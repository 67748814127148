String.prototype.replaceArray = function (find, replace) {
  var replaceString = this;
  var regex;
  for (var i = 0; i < find.length; i++) {
    regex = new RegExp(find[i], "g");
    replaceString = replaceString.replace(regex, replace[i]);
  }
  return replaceString;
};

export const decrypt = (word) => {
  var text = word;
  var find = [
    "Џ",
    "џ",
    "Љ",
    "љ",
    "Њ",
    "њ",
    "Ђ",
    "ђ",
    "Ђ",
    "ђ",
    "а",
    "б",
    "в",
    "г",
    "д",
    "е",
    "ж",
    "з",
    "и",
    "ј",
    "к",
    "л",
    "љ",
    "м",
    "н",
    "о",
    "п",
    "р",
    "с",
    "т",
    "ћ",
    "у",
    "ф",
    "х",
    "ц",
    "ч",
    "ш",
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ж",
    "З",
    "И",
    "Ј",
    "К",
    "Л",
    "Љ",
    "М",
    "Н",
    "Њ",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "Ћ",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Џ",
    "Ш",
  ];
  var replace = [
    "Dž",
    "dž",
    "Lj",
    "lj",
    "Nj",
    "nj",
    "Đ",
    "đ",
    "Dj",
    "dj",
    "a",
    "b",
    "v",
    "g",
    "d",
    "e",
    "ž",
    "z",
    "i",
    "j",
    "k",
    "l",
    "lj",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "ć",
    "u",
    "f",
    "h",
    "c",
    "č",
    "š",
    "A",
    "B",
    "V",
    "G",
    "D",
    "E",
    "Ž",
    "Z",
    "I",
    "J",
    "K",
    "L",
    "Lj",
    "M",
    "N",
    "Nj",
    "O",
    "P",
    "R",
    "S",
    "T",
    "Ć",
    "U",
    "F",
    "H",
    "C",
    "Č",
    "Dž",
    "Š",
  ];
  text = text.replaceArray(find, replace);
  return text;
};

export const encrypt = (word) => {
  var text = word;
  var find = [
    "Dž",
    "dž",
    "Lj",
    "lj",
    "Nj",
    "nj",
    "Đ",
    "đ",
    "Dj",
    "dj",
    "a",
    "b",
    "v",
    "g",
    "d",
    "e",
    "ž",
    "z",
    "i",
    "j",
    "k",
    "l",
    "lj",
    "m",
    "n",
    "o",
    "p",
    "r",
    "s",
    "t",
    "ć",
    "u",
    "f",
    "h",
    "c",
    "č",
    "š",
    "A",
    "B",
    "V",
    "G",
    "D",
    "E",
    "Ž",
    "Z",
    "I",
    "J",
    "K",
    "L",
    "Lj",
    "M",
    "N",
    "Nj",
    "O",
    "P",
    "R",
    "S",
    "T",
    "Ć",
    "U",
    "F",
    "H",
    "C",
    "Č",
    "Dž",
    "Š",
  ];
  var replace = [
    "Џ",
    "џ",
    "Љ",
    "љ",
    "Њ",
    "њ",
    "Ђ",
    "ђ",
    "Ђ",
    "ђ",
    "а",
    "б",
    "в",
    "г",
    "д",
    "е",
    "ж",
    "з",
    "и",
    "ј",
    "к",
    "л",
    "љ",
    "м",
    "н",
    "о",
    "п",
    "р",
    "с",
    "т",
    "ћ",
    "у",
    "ф",
    "х",
    "ц",
    "ч",
    "ш",
    "А",
    "Б",
    "В",
    "Г",
    "Д",
    "Е",
    "Ж",
    "З",
    "И",
    "Ј",
    "К",
    "Л",
    "Љ",
    "М",
    "Н",
    "Њ",
    "О",
    "П",
    "Р",
    "С",
    "Т",
    "Ћ",
    "У",
    "Ф",
    "Х",
    "Ц",
    "Ч",
    "Џ",
    "Ш",
  ];
  text = text.replaceArray(find, replace);
  return text;
};
