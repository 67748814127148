const validWords = [
  "адама",
  "адаме",
  "адаму",
  "адути",
  "афект",
  "афера",
  "афери",
  "аферу",
  "агава",
  "агави",
  "агаву",
  "агенс",
  "агент",
  "агрум",
  "ајвар",
  "акант",
  "аката",
  "акном",
  "акорд",
  "акрап",
  "акрил",
  "актер",
  "актив",
  "алаци",
  "алаха",
  "аларм",
  "алати",
  "албум",
  "алеја",
  "алеји",
  "алеју",
  "алфом",
  "алгом",
  "алиби",
  "алији",
  "алију",
  "алкар",
  "алкој",
  "алком",
  "алтих",
  "алвом",
  "амона",
  "ампер",
  "анала",
  "анали",
  "аналу",
  "анекс",
  "аница",
  "анина",
  "анита",
  "аниту",
  "анића",
  "анићи",
  "анићу",
  "анода",
  "аноди",
  "аноду",
  "ануси",
  "анђел",
  "анђео",
  "аорте",
  "апели",
  "апоен",
  "април",
  "арака",
  "арама",
  "арапу",
  "арбун",
  "ареал",
  "арена",
  "арени",
  "арену",
  "аргон",
  "архив",
  "арија",
  "арима",
  "арина",
  "арини",
  "арину",
  "арком",
  "ароми",
  "арсен",
  "асеан",
  "асова",
  "асови",
  "асову",
  "астма",
  "астми",
  "астму",
  "атаци",
  "аташе",
  "атест",
  "атила",
  "атило",
  "атилу",
  "атлас",
  "атоли",
  "атоми",
  "атова",
  "атови",
  "атриј",
  "аудио",
  "аулом",
  "ауром",
  "аутић",
  "аутор",
  "авана",
  "авани",
  "авану",
  "авдом",
  "аверс",
  "авете",
  "авети",
  "авету",
  "авион",
  "азема",
  "азију",
  "азили",
  "азиза",
  "азура",
  "бабих",
  "бабом",
  "бацач",
  "бацил",
  "бацит",
  "бадем",
  "бадне",
  "багер",
  "багра",
  "бахат",
  "бајам",
  "бајан",
  "бајат",
  "бајци",
  "бајка",
  "бајна",
  "бајни",
  "бајну",
  "бајта",
  "бакар",
  "баком",
  "бакра",
  "бакри",
  "бакру",
  "балет",
  "балон",
  "банак",
  "банци",
  "банда",
  "банем",
  "банеш",
  "банка",
  "банух",
  "банут",
  "банчи",
  "барба",
  "барци",
  "барди",
  "барел",
  "барем",
  "барка",
  "барок",
  "барун",
  "барут",
  "басна",
  "басни",
  "басну",
  "батак",
  "батић",
  "бавит",
  "базар",
  "базен",
  "базга",
  "базги",
  "базгу",
  "базна",
  "базни",
  "базну",
  "базом",
  "бачић",
  "бачка",
  "бачки",
  "бачку",
  "бачва",
  "башка",
  "башча",
  "башчи",
  "башчу",
  "бдија",
  "бдији",
  "бдију",
  "бебом",
  "бедак",
  "бедем",
  "бедра",
  "бедро",
  "бедру",
  "бегом",
  "бехар",
  "беком",
  "белом",
  "белот",
  "бенин",
  "бепом",
  "берач",
  "берба",
  "берем",
  "береш",
  "берна",
  "берну",
  "бесом",
  "бетон",
  "бећар",
  "бечка",
  "бечки",
  "бечку",
  "бечом",
  "бибер",
  "бидеа",
  "бидеи",
  "бидеу",
  "бидом",
  "бифеа",
  "бифеи",
  "бифеу",
  "бијах",
  "бијег",
  "бијел",
  "бијес",
  "бијеш",
  "бијте",
  "биком",
  "биков",
  "билом",
  "биној",
  "бином",
  "биран",
  "бирач",
  "бироа",
  "бирои",
  "бироу",
  "бисер",
  "бисмо",
  "биста",
  "бисти",
  "бисту",
  "битак",
  "битан",
  "битци",
  "битка",
  "битна",
  "бивак",
  "бивол",
  "бивша",
  "бивши",
  "бившу",
  "бизон",
  "бићем",
  "бићих",
  "бичем",
  "бичја",
  "бичји",
  "бичју",
  "бјеху",
  "бјеше",
  "бјеже",
  "бјежи",
  "блата",
  "блажа",
  "блажи",
  "блажу",
  "блеје",
  "блеји",
  "блену",
  "близу",
  "ближа",
  "ближи",
  "ближу",
  "блока",
  "блоку",
  "блуди",
  "блуна",
  "блуни",
  "блуну",
  "блуза",
  "боцим",
  "боцне",
  "боцни",
  "боцну",
  "боцом",
  "боцун",
  "бодац",
  "бодар",
  "бодем",
  "бодеш",
  "бодеж",
  "бодра",
  "бодри",
  "бодру",
  "боеми",
  "богац",
  "богат",
  "богих",
  "богме",
  "боини",
  "бојах",
  "бојем",
  "бојмо",
  "бојна",
  "бојте",
  "бокал",
  "бокца",
  "бокци",
  "бокцу",
  "бокче",
  "болан",
  "болид",
  "болио",
  "болна",
  "болни",
  "болну",
  "болто",
  "бомба",
  "бонна",
  "бонну",
  "боном",
  "бонус",
  "борац",
  "борак",
  "борба",
  "борца",
  "борци",
  "борцу",
  "борик",
  "борит",
  "борић",
  "борја",
  "борју",
  "борна",
  "борче",
  "босна",
  "босни",
  "босог",
  "бости",
  "бовом",
  "бозом",
  "боћај",
  "бочна",
  "божја",
  "божји",
  "божју",
  "божур",
  "браца",
  "брада",
  "брака",
  "браку",
  "брала",
  "брали",
  "брана",
  "брати",
  "брава",
  "браћа",
  "браше",
  "брдом",
  "брека",
  "бреме",
  "бреза",
  "бречи",
  "бређа",
  "бређу",
  "брица",
  "бриди",
  "брига",
  "бриго",
  "бригу",
  "брије",
  "брију",
  "брине",
  "брини",
  "брину",
  "бриса",
  "бризи",
  "брише",
  "бриши",
  "бришу",
  "брлог",
  "броди",
  "броја",
  "броји",
  "броју",
  "броша",
  "брошу",
  "брсти",
  "бртва",
  "бртви",
  "бртву",
  "бруха",
  "бруја",
  "бруји",
  "брују",
  "брука",
  "бруку",
  "бруне",
  "бруси",
  "бруто",
  "брвна",
  "брвно",
  "брвну",
  "брзак",
  "брзог",
  "брчић",
  "бржег",
  "бржем",
  "бржих",
  "бржим",
  "бржој",
  "бржом",
  "бубне",
  "бубни",
  "бубну",
  "бубом",
  "бубре",
  "бубри",
  "будак",
  "будан",
  "будем",
  "будеш",
  "будна",
  "будни",
  "будну",
  "будве",
  "будви",
  "бухом",
  "бујан",
  "бујна",
  "бујни",
  "бујну",
  "букач",
  "букет",
  "букне",
  "букни",
  "букну",
  "букта",
  "букти",
  "буква",
  "булом",
  "бунар",
  "бунца",
  "бунда",
  "буном",
  "бурад",
  "бураг",
  "буран",
  "бурек",
  "бурин",
  "бурма",
  "бурми",
  "бурму",
  "бурна",
  "бурни",
  "бурну",
  "буром",
  "бурза",
  "бусен",
  "бутан",
  "бутик",
  "бучан",
  "бучја",
  "бучна",
  "буђах",
  "бушан",
  "бушач",
  "бушен",
  "бушом",
  "цацма",
  "цакли",
  "цаком",
  "царем",
  "царев",
  "царић",
  "цедар",
  "цехом",
  "цекин",
  "целер",
  "ценер",
  "цента",
  "центи",
  "центу",
  "цепин",
  "цепте",
  "цепти",
  "церит",
  "церја",
  "церју",
  "цеста",
  "цибет",
  "цицао",
  "цицом",
  "цифра",
  "цифри",
  "цифру",
  "цигла",
  "цијев",
  "цијеђ",
  "цијук",
  "цикла",
  "цикне",
  "цикни",
  "цикну",
  "цикће",
  "цикћи",
  "цикћу",
  "цилик",
  "цимер",
  "цимет",
  "цимом",
  "циник",
  "цинка",
  "цинку",
  "ципал",
  "ципар",
  "ципла",
  "ципли",
  "циплу",
  "ципра",
  "ципру",
  "циста",
  "цисти",
  "цисту",
  "цитат",
  "цитри",
  "цивил",
  "цичао",
  "цичим",
  "цичом",
  "цјели",
  "цмари",
  "цокћу",
  "цреса",
  "цркла",
  "цркне",
  "цркни",
  "цркну",
  "црква",
  "црнац",
  "црнца",
  "црнци",
  "црнцу",
  "црнка",
  "црног",
  "црнче",
  "црпао",
  "црпем",
  "црпеш",
  "црпка",
  "црпки",
  "црпку",
  "црпла",
  "црпна",
  "црпни",
  "црпну",
  "цртач",
  "цртеж",
  "цртић",
  "цртом",
  "црвак",
  "црвен",
  "црвић",
  "црвку",
  "цуцка",
  "цуцки",
  "цуцку",
  "цуцла",
  "цугај",
  "цурит",
  "цуром",
  "цвала",
  "цваоа",
  "цвати",
  "цвили",
  "цврче",
  "цврчи",
  "чабар",
  "чабра",
  "чабри",
  "чабру",
  "чајем",
  "чајна",
  "чалма",
  "чалми",
  "чалму",
  "чамац",
  "чамца",
  "чамци",
  "чамцу",
  "чамом",
  "чамче",
  "чанак",
  "чанка",
  "чанку",
  "чарју",
  "чарке",
  "чарну",
  "часак",
  "часка",
  "часку",
  "часна",
  "часом",
  "части",
  "чауша",
  "чавао",
  "чавка",
  "чавла",
  "чавли",
  "чавлу",
  "чазма",
  "чазми",
  "чазму",
  "чађав",
  "чађим",
  "чашка",
  "чашом",
  "чашћу",
  "чедан",
  "чедна",
  "чедом",
  "чекај",
  "чекић",
  "чекрк",
  "челик",
  "челна",
  "челни",
  "челну",
  "челом",
  "чемин",
  "чеона",
  "чепић",
  "чесма",
  "четка",
  "четна",
  "четни",
  "четну",
  "четом",
  "чезне",
  "чезни",
  "чезну",
  "чешај",
  "чешер",
  "чешка",
  "чешки",
  "чешку",
  "чешћа",
  "чешћи",
  "чешћу",
  "чибук",
  "чигри",
  "чијег",
  "чијем",
  "чијих",
  "чијим",
  "чијој",
  "чијом",
  "чиком",
  "чикош",
  "чилаш",
  "чилеа",
  "чилеу",
  "чилих",
  "чилим",
  "чилог",
  "чилој",
  "чилом",
  "чинит",
  "чиода",
  "чиоди",
  "чиоду",
  "чиова",
  "чиову",
  "чипка",
  "чирак",
  "чирић",
  "чиром",
  "числа",
  "числу",
  "читак",
  "читан",
  "читав",
  "читач",
  "читка",
  "чизма",
  "чичак",
  "чичци",
  "чичка",
  "чичку",
  "чичом",
  "чишћа",
  "чишћи",
  "чишћу",
  "чобан",
  "чохом",
  "чојом",
  "чокот",
  "чолак",
  "чопер",
  "чопор",
  "чорба",
  "чрчка",
  "чудак",
  "чудан",
  "чудит",
  "чудна",
  "чудни",
  "чудну",
  "чудом",
  "чујан",
  "чујем",
  "чујеш",
  "чујмо",
  "чујна",
  "чујте",
  "чуком",
  "чулан",
  "чулна",
  "чулни",
  "чулну",
  "чулом",
  "чунак",
  "чунка",
  "чупав",
  "чусмо",
  "чувај",
  "чуван",
  "чувар",
  "чувен",
  "чувши",
  "чучим",
  "чучиш",
  "чврст",
  "ћаска",
  "ћатом",
  "ћаћом",
  "ћелав",
  "ћелом",
  "ћевап",
  "ћифта",
  "ћифти",
  "ћифту",
  "ћилим",
  "ћитаб",
  "ћопио",
  "ћорак",
  "ћорав",
  "ћором",
  "ћосав",
  "ћосом",
  "ћошак",
  "ћошка",
  "ћошку",
  "ћуком",
  "ћулим",
  "ћулом",
  "ћумез",
  "ћурак",
  "ћурке",
  "ћутим",
  "ћутио",
  "ћутиш",
  "ћушка",
  "ћушне",
  "ћушни",
  "ћушну",
  "ћошак",
  "ћурка",
  "дабар",
  "дабра",
  "дабру",
  "дадеш",
  "дадне",
  "дадох",
  "дахне",
  "дахом",
  "дајем",
  "дајеш",
  "дајмо",
  "дајте",
  "дакле",
  "далек",
  "далих",
  "далим",
  "далој",
  "далом",
  "данац",
  "данак",
  "данас",
  "данца",
  "данци",
  "данцу",
  "данка",
  "данку",
  "данте",
  "данче",
  "дарда",
  "дарне",
  "дарни",
  "дарну",
  "дасци",
  "даска",
  "датив",
  "датум",
  "даван",
  "давач",
  "давит",
  "давна",
  "давши",
  "даћој",
  "даћом",
  "дашак",
  "дашку",
  "дашто",
  "дашће",
  "дажди",
  "дебео",
  "дебил",
  "дебла",
  "дебло",
  "деблу",
  "декан",
  "деких",
  "деком",
  "декор",
  "делта",
  "делти",
  "делту",
  "демон",
  "денди",
  "депоа",
  "депои",
  "депоу",
  "деран",
  "дерат",
  "дерби",
  "дерем",
  "дереш",
  "десен",
  "десет",
  "десна",
  "девет",
  "девим",
  "девом",
  "дечка",
  "дигао",
  "дигла",
  "дигли",
  "дигло",
  "дигне",
  "дигни",
  "дигну",
  "дигох",
  "дијел",
  "диких",
  "дикој",
  "диком",
  "дилер",
  "димна",
  "димни",
  "димну",
  "динар",
  "дингу",
  "динка",
  "динку",
  "диоба",
  "диода",
  "диоди",
  "диоду",
  "диона",
  "дирај",
  "дирка",
  "дирне",
  "дирни",
  "дирну",
  "дисат",
  "диска",
  "диску",
  "диван",
  "дивит",
  "дивна",
  "дизах",
  "дизат",
  "дизач",
  "дичан",
  "дична",
  "дични",
  "дичну",
  "дишем",
  "дишеш",
  "дишна",
  "дишни",
  "дишну",
  "дижем",
  "дижеш",
  "длаци",
  "длака",
  "длако",
  "длаку",
  "днима",
  "добар",
  "добит",
  "добна",
  "добни",
  "добну",
  "добоо",
  "добош",
  "добра",
  "додан",
  "додат",
  "додир",
  "додна",
  "догма",
  "догми",
  "догму",
  "догом",
  "дојам",
  "дојци",
  "дојка",
  "дојма",
  "дојми",
  "дојму",
  "докад",
  "доказ",
  "докле",
  "доком",
  "долац",
  "долар",
  "долца",
  "долци",
  "долцу",
  "долет",
  "долих",
  "домар",
  "домет",
  "донде",
  "донио",
  "донле",
  "доном",
  "донос",
  "доора",
  "допао",
  "допис",
  "допре",
  "допри",
  "допро",
  "допру",
  "дорат",
  "досад",
  "досег",
  "досје",
  "доста",
  "дотад",
  "дотле",
  "доток",
  "дотом",
  "доуме",
  "довде",
  "довео",
  "довле",
  "довод",
  "довоз",
  "довре",
  "дозна",
  "дозом",
  "дозре",
  "дочек",
  "дочим",
  "дочој",
  "дочом",
  "дођем",
  "дођеш",
  "дођих",
  "дођох",
  "дошао",
  "дошла",
  "драма",
  "драми",
  "драму",
  "драпа",
  "драпу",
  "дрека",
  "дреса",
  "дресу",
  "дречи",
  "дрхте",
  "дрхти",
  "дрилу",
  "дрима",
  "дримо",
  "дрмеш",
  "дрмне",
  "дрнда",
  "дроби",
  "дроци",
  "дрога",
  "дрозд",
  "дрпај",
  "дрпим",
  "дрска",
  "дружи",
  "дрвар",
  "дрвца",
  "дрвцу",
  "дрвен",
  "дрвим",
  "дрвна",
  "дрвни",
  "дрвну",
  "дрвом",
  "дрзак",
  "дрзне",
  "дрзни",
  "дрзну",
  "дршци",
  "дршка",
  "дршће",
  "дршћи",
  "дршћу",
  "држах",
  "држак",
  "држан",
  "држат",
  "држач",
  "држих",
  "држим",
  "држиш",
  "дубао",
  "дубок",
  "дудук",
  "дуели",
  "дуети",
  "дугме",
  "дугог",
  "духан",
  "духим",
  "духне",
  "духни",
  "духну",
  "дуима",
  "дукат",
  "дукин",
  "дунав",
  "дунда",
  "дупин",
  "дупке",
  "дупла",
  "дурах",
  "дусом",
  "дућан",
  "дућна",
  "душак",
  "душек",
  "душих",
  "душик",
  "душим",
  "душка",
  "душна",
  "душни",
  "душну",
  "душом",
  "дужан",
  "дужда",
  "дужду",
  "дужег",
  "дужем",
  "дужна",
  "двају",
  "двама",
  "двера",
  "двери",
  "двици",
  "двије",
  "двији",
  "двију",
  "двоба",
  "двога",
  "двоја",
  "двоји",
  "двоју",
  "двома",
  "двори",
  "ђакон",
  "ђавао",
  "ђавла",
  "ђавли",
  "ђавлу",
  "ђачић",
  "ђачка",
  "ђачки",
  "ђачку",
  "ђерам",
  "ђерма",
  "ђерми",
  "ђерму",
  "ђоним",
  "ђорај",
  "ђубар",
  "ђубра",
  "ђубри",
  "ђубру",
  "ђуром",
  "ђурђа",
  "ђурђи",
  "ђурђу",
  "ђузел",
  "ђецом",
  "ђедов",
  "ђелце",
  "ђелић",
  "ђељив",
  "ђелом",
  "ђетао",
  "ђетић",
  "ђевац",
  "ђевер",
  "ђевом",
  "ђечак",
  "ђечја",
  "ђечји",
  "ђечју",
  "едеме",
  "едена",
  "едикт",
  "едипа",
  "едипу",
  "ефект",
  "ефезу",
  "егеја",
  "егзил",
  "ехима",
  "екцем",
  "екипа",
  "екран",
  "ексај",
  "елани",
  "елиса",
  "елиси",
  "елису",
  "елита",
  "елзом",
  "емајл",
  "емали",
  "емила",
  "емири",
  "ендем",
  "енеја",
  "ензим",
  "епици",
  "епике",
  "епико",
  "епира",
  "епоха",
  "епохи",
  "епоху",
  "епоси",
  "епова",
  "епови",
  "епска",
  "епски",
  "епску",
  "ерару",
  "есада",
  "есеји",
  "еслој",
  "естет",
  "етани",
  "етапа",
  "етажа",
  "етажи",
  "етажу",
  "етери",
  "етици",
  "етида",
  "етиди",
  "етиду",
  "етика",
  "етику",
  "етнос",
  "етоси",
  "еуром",
  "евала",
  "евица",
  "евици",
  "евицу",
  "фацом",
  "фагот",
  "фајде",
  "факин",
  "факир",
  "факти",
  "фалта",
  "фамом",
  "фарба",
  "фарби",
  "фарбу",
  "фарма",
  "фарми",
  "фарму",
  "фарса",
  "фатум",
  "фауни",
  "фазан",
  "фазна",
  "фазом",
  "федер",
  "фенол",
  "ферал",
  "фесом",
  "фетиш",
  "фетус",
  "фетва",
  "фетви",
  "фетву",
  "фешта",
  "фигом",
  "фијук",
  "фикус",
  "филир",
  "филом",
  "финац",
  "финца",
  "финци",
  "финиш",
  "финог",
  "фирма",
  "фирми",
  "фирму",
  "фишек",
  "флаша",
  "флаши",
  "флашу",
  "флеку",
  "флерт",
  "флеша",
  "флешу",
  "флора",
  "флори",
  "флору",
  "флота",
  "флуид",
  "флуор",
  "фоаје",
  "фоком",
  "фокус",
  "фонди",
  "фонем",
  "форма",
  "фором",
  "форте",
  "форум",
  "фрака",
  "фраку",
  "фрапе",
  "фраза",
  "фркне",
  "фркни",
  "фркну",
  "фрула",
  "фуцај",
  "фугом",
  "фунта",
  "футур",
  "гацке",
  "гадан",
  "гадна",
  "гајба",
  "гајби",
  "гајбу",
  "гајем",
  "гакаш",
  "галеб",
  "галом",
  "галон",
  "галоп",
  "гамад",
  "гамом",
  "ганем",
  "ганга",
  "гангу",
  "ганих",
  "ганут",
  "гарда",
  "гаром",
  "гаров",
  "гасне",
  "гасни",
  "гасну",
  "гатат",
  "гатке",
  "гаван",
  "газда",
  "газит",
  "гажен",
  "гегом",
  "гејши",
  "гелер",
  "гениј",
  "геном",
  "гепек",
  "герма",
  "герми",
  "герму",
  "гесла",
  "гесло",
  "геслу",
  "геста",
  "гетом",
  "гибак",
  "гицај",
  "гинем",
  "гинеш",
  "гипка",
  "гипса",
  "гипсу",
  "гизда",
  "гизди",
  "гизду",
  "глаца",
  "глаци",
  "глацу",
  "глади",
  "гласи",
  "глава",
  "глађу",
  "гледа",
  "глеђи",
  "глина",
  "глоба",
  "глога",
  "глогу",
  "глоса",
  "глоси",
  "глосу",
  "глође",
  "глума",
  "глуши",
  "гмиже",
  "гмижи",
  "гмижу",
  "гнана",
  "гнани",
  "гнано",
  "гнуша",
  "годит",
  "годуј",
  "гогин",
  "гојан",
  "гојна",
  "гојни",
  "гојну",
  "гојој",
  "голем",
  "голет",
  "голфи",
  "голог",
  "голуб",
  "гонга",
  "гонич",
  "гоном",
  "горак",
  "горда",
  "горег",
  "горем",
  "горих",
  "горим",
  "горио",
  "горив",
  "гориш",
  "горја",
  "горју",
  "горка",
  "горој",
  "гором",
  "горча",
  "горчи",
  "горчу",
  "госпа",
  "госпи",
  "госпу",
  "гости",
  "готов",
  "говна",
  "говни",
  "говно",
  "говну",
  "говор",
  "гозба",
  "гошћа",
  "граба",
  "граца",
  "граци",
  "грацу",
  "гради",
  "граха",
  "грахи",
  "граху",
  "граја",
  "грана",
  "грачи",
  "грађа",
  "грбим",
  "грбио",
  "грцне",
  "грдан",
  "грдих",
  "грдна",
  "грдни",
  "грдну",
  "грдој",
  "грдом",
  "гребе",
  "греби",
  "гребу",
  "греда",
  "грејп",
  "гргеч",
  "грије",
  "грију",
  "грима",
  "грипа",
  "грист",
  "грива",
  "гризи",
  "гричу",
  "грижа",
  "грижи",
  "грижу",
  "грлце",
  "грлит",
  "грлић",
  "грлом",
  "грмак",
  "грмим",
  "грмио",
  "грмиш",
  "грмне",
  "грним",
  "грном",
  "грога",
  "грогу",
  "гроом",
  "грота",
  "гроти",
  "гроза",
  "грозд",
  "гроша",
  "груда",
  "груне",
  "груни",
  "груну",
  "група",
  "груша",
  "гружа",
  "гружу",
  "грчка",
  "грђем",
  "грђих",
  "грђим",
  "грђој",
  "грђом",
  "губац",
  "губав",
  "губер",
  "губит",
  "губом",
  "гуцне",
  "гуцни",
  "гуцну",
  "гудба",
  "гудим",
  "гудио",
  "гудиш",
  "гугут",
  "гујој",
  "гујом",
  "гукао",
  "гукну",
  "гулаш",
  "гумби",
  "гумна",
  "гумну",
  "гумом",
  "гунђа",
  "гупче",
  "гурај",
  "гурат",
  "гурка",
  "гурне",
  "гурни",
  "гурну",
  "гуруе",
  "гусак",
  "гусар",
  "гуска",
  "гусла",
  "гусли",
  "гутне",
  "гутни",
  "гутну",
  "гузом",
  "гучом",
  "гушав",
  "гушом",
  "гушћа",
  "гушћи",
  "гушћу",
  "гушче",
  "гужва",
  "гверц",
  "гвири",
  "гвозд",
  "хаага",
  "хааги",
  "хаагу",
  "хаити",
  "хајао",
  "хајци",
  "хајда",
  "хајди",
  "хајду",
  "хајем",
  "хајка",
  "хајки",
  "хајку",
  "хајмо",
  "хајте",
  "халва",
  "хамих",
  "ханом",
  "ханса",
  "ханси",
  "хансу",
  "хапси",
  "харем",
  "харфа",
  "харна",
  "харни",
  "харну",
  "хауба",
  "хашка",
  "хашки",
  "хашку",
  "хелиј",
  "херим",
  "херој",
  "хицем",
  "хицих",
  "хидра",
  "хихот",
  "химба",
  "химна",
  "хитац",
  "хитан",
  "хитар",
  "хитит",
  "хитна",
  "хитра",
  "хивом",
  "хлача",
  "хлачо",
  "ходит",
  "хокеј",
  "хонде",
  "хонду",
  "хорда",
  "хорди",
  "хорду",
  "хором",
  "хотел",
  "хотје",
  "хоћеш",
  "хоћој",
  "хођах",
  "храми",
  "храна",
  "храст",
  "хрбат",
  "хрдај",
  "хриде",
  "хриди",
  "хриду",
  "хрима",
  "хрпом",
  "хрпта",
  "хрпти",
  "хрпту",
  "хрска",
  "хртих",
  "хрушт",
  "хрват",
  "хрвом",
  "хрчак",
  "хрчци",
  "хрчем",
  "хрчка",
  "хрчку",
  "хрђав",
  "хтјет",
  "худих",
  "худим",
  "худој",
  "худом",
  "хугом",
  "хујим",
  "хукао",
  "хумак",
  "хумца",
  "хумци",
  "хумих",
  "хумим",
  "хумка",
  "хумки",
  "хумку",
  "хумор",
  "хумус",
  "хумче",
  "хунта",
  "хунти",
  "хунту",
  "хусар",
  "хучао",
  "хучеш",
  "хучим",
  "хучиш",
  "хучне",
  "хучој",
  "хучом",
  "хушка",
  "хвала",
  "хвара",
  "хвару",
  "хвати",
  "ибрик",
  "идеал",
  "идеја",
  "идемо",
  "идете",
  "идиим",
  "идила",
  "идили",
  "идилу",
  "идима",
  "идиом",
  "идиот",
  "идите",
  "идоли",
  "идућа",
  "идући",
  "идућу",
  "игала",
  "игалу",
  "игара",
  "иглим",
  "иглој",
  "иглом",
  "иглун",
  "игору",
  "играт",
  "играч",
  "игром",
  "икада",
  "икако",
  "икамо",
  "икога",
  "икоја",
  "икоји",
  "икоју",
  "икома",
  "икому",
  "икона",
  "икони",
  "икону",
  "икрих",
  "икром",
  "илици",
  "илицу",
  "илири",
  "илова",
  "илови",
  "илову",
  "имају",
  "имала",
  "имало",
  "имама",
  "имати",
  "имеци",
  "имела",
  "имена",
  "имену",
  "имече",
  "иначе",
  "инима",
  "инких",
  "инога",
  "иноча",
  "иночи",
  "иночу",
  "инска",
  "ински",
  "инску",
  "интел",
  "интим",
  "инћун",
  "ирска",
  "исели",
  "исход",
  "исиса",
  "искат",
  "исказ",
  "искон",
  "ископ",
  "искра",
  "ислам",
  "исмиј",
  "испад",
  "испао",
  "испис",
  "испит",
  "испне",
  "испод",
  "истек",
  "истих",
  "истим",
  "истог",
  "истој",
  "исток",
  "истом",
  "истри",
  "иступ",
  "исула",
  "исули",
  "исуса",
  "исусу",
  "исути",
  "исуши",
  "ивама",
  "ивери",
  "ивица",
  "ивици",
  "ивицу",
  "ивина",
  "ивини",
  "ивину",
  "изасу",
  "изаћи",
  "изађе",
  "изађи",
  "изађу",
  "изаже",
  "избих",
  "избом",
  "избор",
  "избра",
  "издан",
  "изгон",
  "изићи",
  "изиђе",
  "изиђи",
  "изиђу",
  "изјео",
  "излаз",
  "излет",
  "излог",
  "измак",
  "измео",
  "измет",
  "изнад",
  "изнио",
  "износ",
  "изора",
  "израз",
  "изрез",
  "изрод",
  "изује",
  "изују",
  "изуте",
  "изути",
  "изузе",
  "изучи",
  "изван",
  "извод",
  "извор",
  "извоз",
  "ичега",
  "ичему",
  "ичија",
  "ичији",
  "ичију",
  "иђаху",
  "иђаше",
  "ишара",
  "иштем",
  "иштеш",
  "јацој",
  "јадац",
  "јадан",
  "јадит",
  "јадна",
  "јагма",
  "јагми",
  "јагму",
  "јахач",
  "јахта",
  "јахти",
  "јахту",
  "јајца",
  "јајцу",
  "јајна",
  "јакна",
  "јаког",
  "јалан",
  "јални",
  "јалов",
  "јалта",
  "јамац",
  "јамби",
  "јамца",
  "јамци",
  "јамцу",
  "јамим",
  "јамна",
  "јамни",
  "јамну",
  "јамом",
  "јамча",
  "јамчи",
  "јарац",
  "јарад",
  "јарак",
  "јарам",
  "јарца",
  "јарци",
  "јарцу",
  "јарих",
  "јарић",
  "јарка",
  "јарма",
  "јарми",
  "јарму",
  "јарој",
  "јаром",
  "јарче",
  "јасан",
  "јасен",
  "јасла",
  "јасли",
  "јасна",
  "јатим",
  "јауци",
  "јауцу",
  "јауче",
  "јаучи",
  "јаучу",
  "јаван",
  "јавит",
  "јавна",
  "јавом",
  "јавор",
  "јачег",
  "јачем",
  "јачих",
  "јачим",
  "јачој",
  "јачом",
  "јашем",
  "јашеш",
  "јебај",
  "јебем",
  "јецај",
  "јецим",
  "једак",
  "један",
  "једар",
  "једем",
  "једен",
  "једеш",
  "једна",
  "једом",
  "једра",
  "једва",
  "јекне",
  "јекни",
  "јекну",
  "јелек",
  "јелен",
  "јелка",
  "јелој",
  "јелом",
  "јесам",
  "јесен",
  "јесмо",
  "јесте",
  "јести",
  "јетка",
  "јетки",
  "јетку",
  "јетра",
  "јетро",
  "језда",
  "језди",
  "језду",
  "језик",
  "језив",
  "језом",
  "јечам",
  "јечим",
  "јечма",
  "јечму",
  "јечом",
  "јежић",
  "јидиш",
  "јодна",
  "јодни",
  "јодну",
  "јодом",
  "јогој",
  "јогом",
  "јохом",
  "јоинт",
  "јудом",
  "југом",
  "јухом",
  "јулем",
  "јулиа",
  "јулии",
  "јулиу",
  "јунак",
  "јурај",
  "јуриш",
  "јурна",
  "јурни",
  "јурну",
  "јурој",
  "јурто",
  "јутих",
  "јутом",
  "јутра",
  "јучер",
  "јужна",
  "кабао",
  "кабел",
  "кацим",
  "кацом",
  "кадар",
  "кадет",
  "кадра",
  "кадри",
  "кадру",
  "кафић",
  "каган",
  "каире",
  "каиру",
  "каиша",
  "каишу",
  "кајак",
  "кајат",
  "кајде",
  "кајем",
  "кајеш",
  "кајих",
  "кајој",
  "какаа",
  "какао",
  "какав",
  "какој",
  "каква",
  "калај",
  "калем",
  "калеж",
  "калфа",
  "калиј",
  "калуп",
  "камен",
  "камин",
  "камиш",
  "камом",
  "канал",
  "канат",
  "канда",
  "канит",
  "каном",
  "канон",
  "канта",
  "кануи",
  "каоси",
  "капак",
  "капар",
  "капци",
  "капцу",
  "капка",
  "капки",
  "капку",
  "капут",
  "капче",
  "карат",
  "карго",
  "карла",
  "карло",
  "карлу",
  "карма",
  "карми",
  "карму",
  "карта",
  "касан",
  "касач",
  "касна",
  "каста",
  "катар",
  "катна",
  "катни",
  "катну",
  "катун",
  "каубе",
  "каубу",
  "каучи",
  "кавез",
  "кавга",
  "кавим",
  "кавој",
  "кавом",
  "казах",
  "казан",
  "казат",
  "казна",
  "казуј",
  "каћун",
  "каших",
  "кашом",
  "кажем",
  "кажеш",
  "кажих",
  "кажим",
  "кебај",
  "кефир",
  "кефом",
  "кегла",
  "кејих",
  "кејом",
  "кепец",
  "кесих",
  "кесом",
  "кесон",
  "кечке",
  "кибла",
  "кибли",
  "киблу",
  "кицош",
  "кифла",
  "кихао",
  "кихне",
  "кихни",
  "кихну",
  "киким",
  "киком",
  "кикот",
  "килав",
  "килом",
  "кимим",
  "кимле",
  "кимли",
  "киних",
  "киним",
  "кинин",
  "киној",
  "кином",
  "киоск",
  "кипар",
  "кипио",
  "кипић",
  "кипиш",
  "кипти",
  "кисео",
  "кисик",
  "кисна",
  "кисни",
  "кисну",
  "киван",
  "кивна",
  "кићен",
  "кичем",
  "кичма",
  "кишан",
  "кишна",
  "кишој",
  "кишом",
  "клада",
  "клала",
  "клана",
  "клани",
  "клану",
  "клапа",
  "клапи",
  "клапу",
  "клати",
  "клаун",
  "клеки",
  "клела",
  "клели",
  "клело",
  "клера",
  "клери",
  "клеру",
  "клеса",
  "клета",
  "клети",
  "клету",
  "клече",
  "клечи",
  "клеше",
  "клеши",
  "клешу",
  "клица",
  "клифа",
  "клије",
  "клика",
  "клима",
  "клинч",
  "клиза",
  "клизи",
  "клича",
  "кличи",
  "кличу",
  "клижу",
  "клони",
  "клону",
  "клупа",
  "кнеза",
  "кнези",
  "кнезу",
  "кнеже",
  "кобац",
  "кобан",
  "кобцу",
  "кобна",
  "кобој",
  "кобра",
  "кобри",
  "кобру",
  "коцем",
  "коцка",
  "коцој",
  "кодна",
  "кофер",
  "којег",
  "којем",
  "којих",
  "којим",
  "којој",
  "којом",
  "коких",
  "кокој",
  "коком",
  "кокос",
  "кокот",
  "кокош",
  "колац",
  "колан",
  "колар",
  "колат",
  "колач",
  "колца",
  "колци",
  "колцу",
  "колим",
  "колна",
  "колни",
  "колну",
  "колом",
  "колор",
  "колос",
  "колут",
  "колче",
  "комад",
  "комет",
  "комин",
  "конац",
  "конца",
  "конци",
  "концу",
  "конга",
  "конгу",
  "коноп",
  "конта",
  "конто",
  "конту",
  "конус",
  "конва",
  "конви",
  "конву",
  "конче",
  "копар",
  "копач",
  "копка",
  "копна",
  "копре",
  "копун",
  "копча",
  "корак",
  "корал",
  "коров",
  "корпа",
  "корпи",
  "корпу",
  "корза",
  "корзо",
  "корзу",
  "косац",
  "косач",
  "косца",
  "косци",
  "косцу",
  "косит",
  "косна",
  "косни",
  "косну",
  "кости",
  "котац",
  "котао",
  "котар",
  "котач",
  "котла",
  "котлу",
  "котур",
  "котва",
  "котви",
  "котву",
  "кован",
  "ковач",
  "козер",
  "козих",
  "козја",
  "козји",
  "козју",
  "козле",
  "козом",
  "коћим",
  "коћом",
  "кочна",
  "кочни",
  "кочну",
  "кочом",
  "кошем",
  "кошим",
  "кошић",
  "кошта",
  "кошћу",
  "кожар",
  "кожна",
  "кожни",
  "кожну",
  "кожом",
  "кожух",
  "краца",
  "краци",
  "краде",
  "кради",
  "краду",
  "краха",
  "краху",
  "краки",
  "крало",
  "крама",
  "крами",
  "крамп",
  "краму",
  "краси",
  "краст",
  "крати",
  "крату",
  "крава",
  "краћа",
  "краћи",
  "краћу",
  "краче",
  "крађа",
  "краша",
  "краши",
  "крашу",
  "крцат",
  "крдој",
  "крдом",
  "креда",
  "крема",
  "крена",
  "крени",
  "крену",
  "крећа",
  "крећи",
  "крећу",
  "кречи",
  "крешу",
  "крхај",
  "крхак",
  "крхка",
  "крица",
  "крици",
  "крије",
  "крију",
  "крила",
  "крима",
  "крину",
  "крити",
  "криза",
  "криче",
  "кричи",
  "кркао",
  "крмак",
  "крмар",
  "крмци",
  "крмка",
  "крмку",
  "крмна",
  "крмни",
  "крмну",
  "крмој",
  "крмом",
  "крмче",
  "кроки",
  "кроми",
  "кропи",
  "кроти",
  "кроза",
  "крочи",
  "крпих",
  "крпом",
  "крсна",
  "круге",
  "круги",
  "круха",
  "круху",
  "крула",
  "крули",
  "круна",
  "крупе",
  "крућа",
  "крући",
  "крућу",
  "кружи",
  "крвав",
  "крвих",
  "крвим",
  "крвна",
  "крзма",
  "крзна",
  "крзни",
  "крзно",
  "крзну",
  "крчаг",
  "крчих",
  "крчка",
  "крчма",
  "крчом",
  "кршан",
  "кршке",
  "кршна",
  "кршни",
  "кршну",
  "кршој",
  "кубна",
  "кубни",
  "кубну",
  "куцна",
  "куцни",
  "куцну",
  "кудој",
  "кудом",
  "куфер",
  "кугла",
  "кугом",
  "кухан",
  "кухар",
  "кујеш",
  "кујом",
  "кукац",
  "кукца",
  "кукци",
  "кукцу",
  "куким",
  "кукче",
  "кулен",
  "кулим",
  "кулом",
  "кумин",
  "кумир",
  "кумче",
  "кунем",
  "кунеш",
  "куних",
  "кунић",
  "куном",
  "купац",
  "купач",
  "купца",
  "купци",
  "купцу",
  "купем",
  "купит",
  "купка",
  "купон",
  "купус",
  "купче",
  "курац",
  "курда",
  "курди",
  "курен",
  "курих",
  "курир",
  "курит",
  "курја",
  "курји",
  "курју",
  "куром",
  "курва",
  "кутак",
  "кутић",
  "кутка",
  "кутку",
  "кутна",
  "кутре",
  "кутри",
  "кутру",
  "кућна",
  "кућој",
  "кућом",
  "кучја",
  "кучји",
  "кучју",
  "кучко",
  "кужан",
  "кужим",
  "кужиш",
  "кужна",
  "кужни",
  "кужну",
  "квака",
  "квант",
  "кварц",
  "квари",
  "кварт",
  "кваси",
  "квача",
  "квачи",
  "квачу",
  "квизи",
  "квота",
  "кврга",
  "кврзи",
  "кћери",
  "лабав",
  "лабуд",
  "лаган",
  "лагер",
  "лагум",
  "лагва",
  "лагви",
  "лагву",
  "лахор",
  "лаици",
  "лаиче",
  "лајав",
  "лајем",
  "лајеш",
  "лајих",
  "лајом",
  "лајте",
  "лакат",
  "лакеј",
  "лаког",
  "лаком",
  "лакта",
  "лакти",
  "лакту",
  "лакша",
  "лакши",
  "лакшу",
  "лампа",
  "ланац",
  "ланад",
  "ланца",
  "ланци",
  "ланцу",
  "ланен",
  "ланим",
  "лануо",
  "ланче",
  "лапис",
  "лапор",
  "лапће",
  "ларма",
  "ларми",
  "ларму",
  "ларва",
  "ларви",
  "ларву",
  "ласер",
  "ласих",
  "ласју",
  "ласка",
  "ласом",
  "ласта",
  "лауди",
  "лаура",
  "лаури",
  "лауру",
  "лавеж",
  "лавор",
  "лазим",
  "лазом",
  "лађар",
  "лађим",
  "лађој",
  "лађом",
  "лашца",
  "лашци",
  "лашцу",
  "лашти",
  "лашче",
  "лажац",
  "лажан",
  "лажем",
  "лажеш",
  "лажих",
  "лажју",
  "лажна",
  "лажов",
  "лебда",
  "лебди",
  "лебду",
  "лецих",
  "лецне",
  "лецни",
  "лецну",
  "ледац",
  "ледца",
  "ледци",
  "ледцу",
  "леден",
  "легао",
  "легат",
  "легла",
  "легне",
  "легни",
  "легну",
  "легох",
  "лејла",
  "лемеш",
  "леном",
  "лента",
  "лепра",
  "летак",
  "летач",
  "летци",
  "летио",
  "летиш",
  "летка",
  "летку",
  "летва",
  "леути",
  "лећом",
  "леђен",
  "леђна",
  "леђој",
  "лежај",
  "лежим",
  "лежиш",
  "лежој",
  "либар",
  "либор",
  "либра",
  "либри",
  "либру",
  "лицем",
  "лицих",
  "лицим",
  "лицој",
  "лидер",
  "лифти",
  "лигхт",
  "лигој",
  "лигом",
  "лихве",
  "лијек",
  "лијен",
  "лијеп",
  "лијер",
  "лијес",
  "лијев",
  "лијих",
  "лијим",
  "лијој",
  "лијом",
  "ликер",
  "ликом",
  "лимар",
  "лимес",
  "лимфа",
  "лимфи",
  "лимфу",
  "лимим",
  "лимит",
  "лимун",
  "линки",
  "линча",
  "липих",
  "липом",
  "липше",
  "лирик",
  "лирој",
  "лиром",
  "лисац",
  "лисца",
  "лисци",
  "лисцу",
  "лисја",
  "лисју",
  "лиска",
  "лиски",
  "лиску",
  "лисна",
  "лисни",
  "лисну",
  "литиј",
  "литра",
  "ливац",
  "ливен",
  "ливој",
  "ливом",
  "лизне",
  "лизни",
  "лизну",
  "лизол",
  "личан",
  "личен",
  "личка",
  "лички",
  "личку",
  "лична",
  "личом",
  "лишај",
  "лишца",
  "лишцу",
  "лишен",
  "лишћа",
  "лишћи",
  "лишћу",
  "лижем",
  "лижеш",
  "лижим",
  "логих",
  "логим",
  "логои",
  "логом",
  "логор",
  "лојем",
  "лојим",
  "лојна",
  "лојни",
  "лојну",
  "локал",
  "локне",
  "локот",
  "локва",
  "лолој",
  "лолом",
  "ломан",
  "ломна",
  "ломни",
  "ломну",
  "лонац",
  "лонца",
  "лонци",
  "лонцу",
  "лонче",
  "лопов",
  "лопоч",
  "лопта",
  "лосос",
  "лотим",
  "лотом",
  "лотос",
  "ловац",
  "ловца",
  "ловци",
  "ловцу",
  "ловит",
  "ловна",
  "ловор",
  "ловче",
  "лозом",
  "лочем",
  "лођих",
  "лођом",
  "лошег",
  "лошем",
  "ложач",
  "ложих",
  "ложом",
  "лубин",
  "лудог",
  "лугар",
  "лугим",
  "лукав",
  "луких",
  "лукин",
  "лулих",
  "лулој",
  "лулом",
  "лумен",
  "лумин",
  "лумпа",
  "лунар",
  "луним",
  "луном",
  "лупеж",
  "лупне",
  "лупни",
  "лупну",
  "лупој",
  "лупом",
  "лутка",
  "лучац",
  "лучца",
  "лучци",
  "лучцу",
  "лучић",
  "лучју",
  "лучка",
  "лучна",
  "лучни",
  "лучну",
  "лучом",
  "луђак",
  "луђих",
  "луђим",
  "луђој",
  "луђом",
  "љагих",
  "љагим",
  "љагој",
  "љагом",
  "љамим",
  "љамом",
  "љекар",
  "љењем",
  "љењих",
  "љењим",
  "љењој",
  "љењом",
  "љепки",
  "љепша",
  "љепши",
  "љепшу",
  "љетих",
  "љетна",
  "љетно",
  "љетој",
  "љетом",
  "љевак",
  "љевач",
  "љевен",
  "љигав",
  "љиљан",
  "љосне",
  "љосни",
  "љосну",
  "љубак",
  "љубав",
  "љубих",
  "љубит",
  "љубој",
  "љубом",
  "људој",
  "људом",
  "љуљан",
  "љупка",
  "љупки",
  "љупку",
  "љупче",
  "љусци",
  "љуска",
  "љутит",
  "љутић",
  "љутња",
  "љутњи",
  "љутњу",
  "љућих",
  "љућим",
  "љућој",
  "љућом",
  "љушта",
  "љушти",
  "мацан",
  "мацих",
  "мацом",
  "мадеж",
  "магла",
  "магма",
  "магми",
  "магму",
  "махим",
  "махне",
  "махни",
  "махну",
  "махом",
  "мајци",
  "мајим",
  "мајка",
  "мајки",
  "мајку",
  "мајор",
  "мајур",
  "макац",
  "макао",
  "макар",
  "маким",
  "макне",
  "макни",
  "макну",
  "макох",
  "маком",
  "макро",
  "макса",
  "макси",
  "максу",
  "мален",
  "малер",
  "малих",
  "малим",
  "малне",
  "малог",
  "малој",
  "малом",
  "малто",
  "мамац",
  "мамца",
  "мамци",
  "мамцу",
  "мамих",
  "мамит",
  "мамом",
  "мамут",
  "мамче",
  "манга",
  "манго",
  "маном",
  "мапим",
  "мапом",
  "марци",
  "марих",
  "марка",
  "марна",
  "марни",
  "марну",
  "марод",
  "марој",
  "маром",
  "марса",
  "марсу",
  "марва",
  "марша",
  "марши",
  "маршу",
  "маржа",
  "масер",
  "масих",
  "масив",
  "маска",
  "масло",
  "маслу",
  "масна",
  "масом",
  "масон",
  "масти",
  "матер",
  "мазач",
  "мазаш",
  "мазга",
  "мазна",
  "мазни",
  "мазну",
  "мазој",
  "мазом",
  "мазут",
  "мачак",
  "мачем",
  "мачић",
  "мачја",
  "мачји",
  "мачју",
  "мачка",
  "машем",
  "машеш",
  "машка",
  "машки",
  "машку",
  "машна",
  "машта",
  "машћу",
  "мажем",
  "меден",
  "медиј",
  "медим",
  "медна",
  "медни",
  "медну",
  "мекан",
  "мекет",
  "меког",
  "мекша",
  "мекши",
  "мекшу",
  "мелем",
  "мелез",
  "мелон",
  "мелос",
  "мемли",
  "менза",
  "месар",
  "месна",
  "месни",
  "месну",
  "месом",
  "мести",
  "метак",
  "метал",
  "метан",
  "метар",
  "метци",
  "метем",
  "метеш",
  "метеж",
  "метка",
  "метку",
  "метла",
  "метне",
  "метни",
  "метну",
  "метом",
  "метра",
  "мезиј",
  "мећем",
  "мећеш",
  "међим",
  "међом",
  "мидер",
  "мигне",
  "мигни",
  "мигну",
  "мигом",
  "михом",
  "мијех",
  "мијеш",
  "микро",
  "милих",
  "милит",
  "милог",
  "милој",
  "милом",
  "минер",
  "мином",
  "минух",
  "минус",
  "минут",
  "миран",
  "мираз",
  "мирис",
  "мирка",
  "мирна",
  "мисал",
  "мисао",
  "мисли",
  "мисна",
  "мисни",
  "мисну",
  "мисом",
  "митра",
  "митри",
  "митру",
  "мићен",
  "мичем",
  "мичеш",
  "мишар",
  "мишци",
  "мишем",
  "мишић",
  "мишја",
  "мишји",
  "мишју",
  "мишка",
  "мједа",
  "мједи",
  "мједо",
  "мјера",
  "млаци",
  "млати",
  "млаза",
  "млазу",
  "млача",
  "млачи",
  "млачу",
  "млађа",
  "млађи",
  "млађу",
  "млини",
  "многа",
  "мноме",
  "множи",
  "моаре",
  "мобом",
  "модар",
  "модел",
  "модем",
  "модна",
  "модом",
  "модра",
  "модул",
  "модус",
  "могах",
  "могао",
  "могла",
  "могли",
  "могло",
  "могне",
  "могох",
  "могућ",
  "мојег",
  "мојем",
  "мокар",
  "мокра",
  "молба",
  "молит",
  "момак",
  "момци",
  "момка",
  "момку",
  "момче",
  "монах",
  "мопед",
  "морал",
  "морем",
  "мором",
  "моржа",
  "моржу",
  "мосна",
  "мосор",
  "мости",
  "мотел",
  "мотет",
  "мотив",
  "мотка",
  "мотом",
  "мотор",
  "мотри",
  "мозак",
  "мозга",
  "мозгу",
  "моћан",
  "моћна",
  "мошта",
  "мошту",
  "мошус",
  "можда",
  "можеш",
  "мрака",
  "мраку",
  "мрави",
  "мраза",
  "мразу",
  "мрачи",
  "мраше",
  "мраже",
  "мрдај",
  "мрдне",
  "мремо",
  "мрена",
  "мрежа",
  "мркле",
  "мркне",
  "мркни",
  "мркну",
  "мрква",
  "мрмор",
  "мрска",
  "мрсне",
  "мртав",
  "мртва",
  "мрвом",
  "мрзак",
  "мрзла",
  "мрзли",
  "мрзлу",
  "мрзне",
  "мрчих",
  "мрчој",
  "мрчом",
  "мршав",
  "мршти",
  "мудар",
  "мудра",
  "мухом",
  "мукам",
  "мукао",
  "мукат",
  "мукин",
  "мукла",
  "мулца",
  "мулци",
  "мулцу",
  "мулом",
  "мунго",
  "мунзи",
  "мурги",
  "мурва",
  "мурви",
  "мурву",
  "мусти",
  "мутан",
  "мутав",
  "мутна",
  "музао",
  "музеј",
  "музем",
  "музеш",
  "музла",
  "музом",
  "мућак",
  "мућка",
  "мучан",
  "мучит",
  "мучка",
  "мучки",
  "мучку",
  "мучна",
  "мушка",
  "мужар",
  "мужем",
  "мужић",
  "набих",
  "набла",
  "набој",
  "набор",
  "набра",
  "нацрт",
  "надме",
  "надро",
  "надру",
  "надут",
  "нафта",
  "нагао",
  "нагиб",
  "нагла",
  "нагна",
  "нагни",
  "нагну",
  "нагог",
  "нагон",
  "наиме",
  "наићи",
  "наиђе",
  "наиђи",
  "наиђу",
  "најам",
  "најео",
  "најма",
  "најми",
  "најму",
  "накит",
  "након",
  "накуј",
  "налаз",
  "налет",
  "налик",
  "налог",
  "намет",
  "намре",
  "намри",
  "намро",
  "намру",
  "нанио",
  "нанос",
  "наоко",
  "наору",
  "наочи",
  "напад",
  "напех",
  "напет",
  "напих",
  "напис",
  "напне",
  "напни",
  "напну",
  "напој",
  "напон",
  "напор",
  "нарав",
  "народ",
  "насад",
  "насип",
  "насла",
  "наспе",
  "натру",
  "науци",
  "наука",
  "научи",
  "навих",
  "навод",
  "навој",
  "навре",
  "наврх",
  "назад",
  "назал",
  "назив",
  "назор",
  "назре",
  "назуј",
  "назва",
  "начас",
  "начин",
  "начне",
  "начни",
  "начну",
  "начуј",
  "нађем",
  "нађен",
  "нађеш",
  "нађох",
  "нашао",
  "нашег",
  "нашем",
  "нашла",
  "нашли",
  "нажао",
  "небом",
  "негда",
  "нехај",
  "нехат",
  "нејак",
  "нејач",
  "некад",
  "неких",
  "неким",
  "неког",
  "некој",
  "неком",
  "некоћ",
  "некуд",
  "неман",
  "немар",
  "немио",
  "немир",
  "немој",
  "немоћ",
  "неони",
  "непал",
  "непца",
  "непцу",
  "непун",
  "нерад",
  "нерца",
  "нерцу",
  "неред",
  "нерви",
  "несен",
  "несло",
  "неста",
  "нести",
  "нетко",
  "нетом",
  "невен",
  "невин",
  "нећак",
  "нећеш",
  "нећка",
  "нечег",
  "нечем",
  "нечим",
  "нешто",
  "нежив",
  "нијем",
  "никад",
  "никал",
  "никао",
  "никим",
  "никла",
  "никне",
  "никни",
  "никну",
  "ником",
  "никуд",
  "нимфа",
  "нисам",
  "ниска",
  "нисмо",
  "нисте",
  "нитко",
  "нивоа",
  "нивои",
  "нивоу",
  "низак",
  "низом",
  "ничем",
  "ничеш",
  "ничим",
  "нишан",
  "нишом",
  "ништа",
  "ништи",
  "нижег",
  "нижем",
  "нижеш",
  "нижих",
  "нижим",
  "нижој",
  "нижом",
  "ногом",
  "нојем",
  "нокат",
  "нокта",
  "нокти",
  "нокту",
  "номад",
  "норма",
  "носач",
  "носит",
  "носић",
  "носна",
  "носни",
  "носну",
  "нотар",
  "нотес",
  "нотна",
  "новац",
  "новак",
  "новца",
  "новци",
  "новцу",
  "новог",
  "ноћас",
  "ноћца",
  "ноћци",
  "ноћцу",
  "ноћит",
  "ноћна",
  "ноћни",
  "ноћну",
  "ношен",
  "ножем",
  "ножић",
  "ножна",
  "ножни",
  "ножну",
  "нудит",
  "нулом",
  "нулта",
  "нулти",
  "нулту",
  "нутка",
  "нуђен",
  "нужан",
  "нужда",
  "нужна",
  "њедра",
  "његом",
  "његов",
  "њезин",
  "њежан",
  "њежна",
  "њихај",
  "њихов",
  "њивом",
  "њишеш",
  "њушци",
  "њушим",
  "њушиш",
  "њушка",
  "оапец",
  "оазом",
  "обади",
  "обају",
  "обала",
  "обара",
  "обасу",
  "обави",
  "обаћи",
  "обађе",
  "обдан",
  "обере",
  "обећа",
  "обија",
  "обију",
  "обими",
  "обите",
  "обити",
  "обићи",
  "обиђе",
  "обиђи",
  "обиђу",
  "објед",
  "облак",
  "облик",
  "облим",
  "облог",
  "облој",
  "облом",
  "обноћ",
  "ободи",
  "обоји",
  "оболи",
  "обома",
  "обоом",
  "обрат",
  "образ",
  "обред",
  "обрис",
  "обрне",
  "обрни",
  "обрну",
  "оброк",
  "обрти",
  "обруб",
  "обруч",
  "обрва",
  "обрће",
  "обуци",
  "обује",
  "обука",
  "обуко",
  "обуку",
  "обути",
  "обува",
  "обузе",
  "обућа",
  "обзир",
  "оцеан",
  "оцима",
  "оцрни",
  "оцрти",
  "оцтим",
  "оцтом",
  "оцвло",
  "одаха",
  "одаја",
  "одапе",
  "одара",
  "одари",
  "одару",
  "одати",
  "одбор",
  "одемо",
  "одера",
  "одери",
  "одеру",
  "одете",
  "одгој",
  "одире",
  "одићи",
  "одишу",
  "одижу",
  "одлих",
  "одлиј",
  "одмах",
  "одмак",
  "одмет",
  "одмор",
  "однио",
  "однос",
  "одока",
  "одоле",
  "одоли",
  "одора",
  "одори",
  "одору",
  "одраз",
  "одред",
  "одрод",
  "одром",
  "одрон",
  "одржа",
  "одржи",
  "одсад",
  "одука",
  "одуку",
  "одузе",
  "одучи",
  "одужи",
  "одвео",
  "одвећ",
  "одвих",
  "одвод",
  "одвоз",
  "офури",
  "оглас",
  "оглед",
  "оголи",
  "огриј",
  "огрне",
  "огрни",
  "огрну",
  "огули",
  "ојади",
  "ојача",
  "ојкао",
  "окаја",
  "окају",
  "окана",
  "окани",
  "окапа",
  "оката",
  "окати",
  "окату",
  "окити",
  "оклоп",
  "окном",
  "около",
  "окоти",
  "окови",
  "окрет",
  "окрпи",
  "округ",
  "оксид",
  "октан",
  "октет",
  "окуци",
  "окује",
  "окују",
  "окука",
  "окуку",
  "окупа",
  "окупи",
  "окупу",
  "окуша",
  "окуже",
  "оквир",
  "олако",
  "олиба",
  "олова",
  "олово",
  "олову",
  "олоша",
  "олоши",
  "олошу",
  "олтар",
  "олуци",
  "олуја",
  "олупи",
  "олуче",
  "омаци",
  "омама",
  "омара",
  "омари",
  "омару",
  "омата",
  "омаза",
  "омела",
  "омело",
  "омест",
  "омета",
  "омети",
  "омету",
  "омеђи",
  "омили",
  "омима",
  "омичу",
  "омјер",
  "омлет",
  "омоти",
  "омоче",
  "омочи",
  "омрсе",
  "омрси",
  "омска",
  "омчом",
  "онако",
  "онамо",
  "онога",
  "оному",
  "онуда",
  "ончас",
  "опада",
  "опаса",
  "опати",
  "опази",
  "опаше",
  "опаши",
  "опашу",
  "опажа",
  "опеци",
  "опека",
  "опеко",
  "опеку",
  "опела",
  "опелу",
  "опера",
  "опери",
  "оперу",
  "опећи",
  "опече",
  "опход",
  "опија",
  "опију",
  "опили",
  "опипи",
  "опире",
  "описи",
  "опита",
  "опити",
  "опиту",
  "опише",
  "опиши",
  "опишу",
  "опкоп",
  "оплео",
  "опном",
  "опрах",
  "опран",
  "опрат",
  "опрез",
  "опржи",
  "опсег",
  "опсуј",
  "опток",
  "опуси",
  "опуте",
  "опћег",
  "опћем",
  "опћих",
  "опћој",
  "опћом",
  "опших",
  "опшиј",
  "опшим",
  "орада",
  "орала",
  "ораси",
  "орати",
  "орача",
  "орачи",
  "орачу",
  "ораше",
  "орден",
  "оремо",
  "орена",
  "орени",
  "орену",
  "ореол",
  "орете",
  "орган",
  "орило",
  "ориси",
  "орити",
  "орише",
  "оркан",
  "орлић",
  "орлом",
  "ормар",
  "ормом",
  "орној",
  "ороби",
  "ороси",
  "орози",
  "ортак",
  "оруђа",
  "оруђу",
  "осека",
  "осеко",
  "осеку",
  "осица",
  "осици",
  "осицу",
  "осили",
  "осима",
  "осина",
  "осини",
  "осину",
  "осипи",
  "осјет",
  "ослић",
  "ослом",
  "осмих",
  "осмим",
  "осмог",
  "осмој",
  "осмом",
  "осних",
  "осним",
  "осној",
  "осном",
  "оснуј",
  "особа",
  "осови",
  "оспем",
  "осуда",
  "осује",
  "осула",
  "осули",
  "осуши",
  "освит",
  "осврт",
  "отаца",
  "отада",
  "отале",
  "отаре",
  "отари",
  "отару",
  "отава",
  "отави",
  "отаву",
  "отцем",
  "отеци",
  "отеку",
  "отела",
  "отели",
  "отепе",
  "отепи",
  "отепу",
  "отеса",
  "отета",
  "отети",
  "отећи",
  "отече",
  "отежа",
  "отежи",
  "отежу",
  "отире",
  "отићи",
  "отиђе",
  "отиђи",
  "отиђу",
  "откад",
  "отказ",
  "откуј",
  "откуп",
  "отмем",
  "отмеш",
  "отоци",
  "отопи",
  "оточи",
  "отпад",
  "отпис",
  "отпор",
  "отрца",
  "отрем",
  "отрла",
  "отров",
  "отрта",
  "отрти",
  "отрту",
  "отруј",
  "отрча",
  "отрчи",
  "отрже",
  "отуда",
  "отупи",
  "отући",
  "отуђи",
  "отвор",
  "оваца",
  "овала",
  "овали",
  "овалу",
  "овамо",
  "овцом",
  "овећа",
  "овећи",
  "овећу",
  "овија",
  "овију",
  "овило",
  "овима",
  "овиси",
  "овита",
  "овити",
  "овито",
  "овјес",
  "овлаш",
  "овном",
  "овога",
  "овоји",
  "овоме",
  "овому",
  "оврха",
  "оврху",
  "оврси",
  "оврши",
  "овуда",
  "овчар",
  "овчас",
  "овчја",
  "овчји",
  "овчју",
  "озаре",
  "оздол",
  "озебе",
  "озеби",
  "озебу",
  "озиру",
  "озони",
  "оћуте",
  "оћути",
  "очаји",
  "очала",
  "очара",
  "очица",
  "очију",
  "очима",
  "очних",
  "очним",
  "очној",
  "очном",
  "очухи",
  "очуси",
  "очува",
  "очуше",
  "ошине",
  "ошини",
  "ошину",
  "ошиша",
  "оштар",
  "оштра",
  "ожега",
  "ожеме",
  "ожени",
  "ожета",
  "ожети",
  "ожету",
  "оживи",
  "ожмем",
  "пацер",
  "пацка",
  "пацки",
  "пацку",
  "падеж",
  "падне",
  "падни",
  "падну",
  "пајзл",
  "пакао",
  "пакет",
  "пакла",
  "пакли",
  "паклу",
  "палац",
  "палца",
  "палци",
  "палцу",
  "палеж",
  "палит",
  "палма",
  "палом",
  "палче",
  "памет",
  "памти",
  "памук",
  "паноа",
  "панои",
  "паноу",
  "панта",
  "панти",
  "панту",
  "папак",
  "папар",
  "папци",
  "папир",
  "папка",
  "папку",
  "папом",
  "папра",
  "папри",
  "папру",
  "параф",
  "паран",
  "парба",
  "парби",
  "парбу",
  "парип",
  "парић",
  "парка",
  "парку",
  "парна",
  "парох",
  "парче",
  "пасао",
  "пасат",
  "пасем",
  "пасеш",
  "пасив",
  "пасја",
  "пасји",
  "пасју",
  "паска",
  "паски",
  "паску",
  "пасла",
  "пасли",
  "пасош",
  "паста",
  "пасус",
  "патак",
  "патци",
  "патка",
  "пауци",
  "пауни",
  "пауза",
  "паузи",
  "паузу",
  "пауче",
  "павла",
  "павлу",
  "пазар",
  "пазит",
  "пачат",
  "пачић",
  "пачја",
  "пачји",
  "пачју",
  "пашем",
  "пашеш",
  "пашин",
  "пашка",
  "пашки",
  "пашку",
  "пашом",
  "пашта",
  "пашти",
  "пашту",
  "пашче",
  "пецне",
  "пецни",
  "пецну",
  "педер",
  "пегла",
  "пехар",
  "пехом",
  "пекао",
  "пекар",
  "пекла",
  "пекли",
  "пекло",
  "пеком",
  "пелин",
  "пелуд",
  "пенал",
  "пенис",
  "пепео",
  "перад",
  "перач",
  "перца",
  "перцу",
  "перец",
  "перем",
  "переш",
  "перја",
  "перји",
  "перју",
  "перла",
  "перли",
  "перлу",
  "пером",
  "перон",
  "перут",
  "перуу",
  "пести",
  "петак",
  "петар",
  "петит",
  "петка",
  "петку",
  "петог",
  "петра",
  "петру",
  "пећар",
  "печат",
  "печем",
  "печен",
  "печеш",
  "печом",
  "пешта",
  "пешту",
  "пешћу",
  "пијан",
  "пијем",
  "пијеш",
  "пијмо",
  "пијте",
  "пијук",
  "пијун",
  "пикај",
  "пилад",
  "пилар",
  "пилав",
  "пилом",
  "пилот",
  "пинкл",
  "пинта",
  "пипац",
  "пипав",
  "пипца",
  "пипци",
  "пипцу",
  "пипке",
  "пипом",
  "пипче",
  "пират",
  "пирем",
  "пирит",
  "пирја",
  "пирни",
  "пирот",
  "писац",
  "писах",
  "писак",
  "писан",
  "писар",
  "писат",
  "писач",
  "писца",
  "писци",
  "писцу",
  "писка",
  "писку",
  "писма",
  "писмо",
  "писму",
  "писне",
  "писни",
  "писну",
  "писта",
  "писти",
  "писту",
  "питак",
  "питан",
  "питка",
  "питом",
  "питон",
  "пивар",
  "пивом",
  "пивот",
  "пизда",
  "пизди",
  "пизду",
  "пизма",
  "пићем",
  "пичку",
  "пишем",
  "пишеш",
  "пишта",
  "пјега",
  "пјего",
  "пјегу",
  "пјена",
  "пјези",
  "плаци",
  "плани",
  "пласт",
  "плата",
  "плаза",
  "плази",
  "плазу",
  "плаћа",
  "плача",
  "плачи",
  "плачу",
  "плаши",
  "плашт",
  "плажа",
  "плажи",
  "плажу",
  "плеха",
  "плеху",
  "плела",
  "плело",
  "племе",
  "плеса",
  "плест",
  "плесу",
  "плете",
  "плети",
  "плету",
  "плећа",
  "плећи",
  "плеше",
  "плеши",
  "плешу",
  "плима",
  "плива",
  "пливи",
  "плића",
  "плићи",
  "плићу",
  "плоди",
  "плоха",
  "плови",
  "плоча",
  "плуга",
  "плугу",
  "плута",
  "плуто",
  "плуту",
  "плућа",
  "плући",
  "плуже",
  "побиј",
  "побра",
  "подат",
  "подиј",
  "подла",
  "подна",
  "подре",
  "поема",
  "поеми",
  "поему",
  "поени",
  "поета",
  "поети",
  "поету",
  "поган",
  "погна",
  "погни",
  "погну",
  "погон",
  "поход",
  "појац",
  "појам",
  "појас",
  "појео",
  "појма",
  "појми",
  "појму",
  "показ",
  "покер",
  "покла",
  "покој",
  "поком",
  "покоп",
  "покус",
  "полет",
  "полих",
  "полиј",
  "полип",
  "полит",
  "полог",
  "помак",
  "помет",
  "помна",
  "помни",
  "помну",
  "помор",
  "помоћ",
  "помпа",
  "помру",
  "понад",
  "понио",
  "понор",
  "понос",
  "поноћ",
  "поора",
  "попех",
  "попет",
  "попих",
  "попис",
  "попне",
  "попни",
  "попну",
  "попра",
  "попут",
  "порат",
  "пораз",
  "поред",
  "порез",
  "порим",
  "порив",
  "порно",
  "пород",
  "порок",
  "пором",
  "порта",
  "порти",
  "порту",
  "поруб",
  "посао",
  "посиј",
  "посла",
  "послу",
  "посна",
  "посни",
  "посну",
  "поспе",
  "поста",
  "посут",
  "посве",
  "потез",
  "потка",
  "потки",
  "потку",
  "поток",
  "потом",
  "потоп",
  "потре",
  "поуци",
  "поука",
  "поуко",
  "поуку",
  "поучи",
  "повез",
  "пових",
  "повик",
  "повод",
  "повој",
  "поврх",
  "позер",
  "позив",
  "позна",
  "позом",
  "позор",
  "позва",
  "почет",
  "почне",
  "почни",
  "почну",
  "пођем",
  "пођеш",
  "пођох",
  "пошао",
  "пошла",
  "пошли",
  "пошло",
  "пошта",
  "пожар",
  "пожме",
  "пожми",
  "пожму",
  "прага",
  "прагу",
  "праха",
  "праху",
  "прала",
  "прали",
  "прало",
  "прасе",
  "прати",
  "преци",
  "преда",
  "преди",
  "преду",
  "преко",
  "прела",
  "прели",
  "прелу",
  "према",
  "прене",
  "прени",
  "прену",
  "преса",
  "преви",
  "презл",
  "прећи",
  "преча",
  "пређа",
  "преша",
  "пргав",
  "прхак",
  "прхка",
  "прхне",
  "прхни",
  "прхну",
  "прхут",
  "прици",
  "прија",
  "прима",
  "прими",
  "принц",
  "приор",
  "прису",
  "прићи",
  "прича",
  "приђе",
  "приђи",
  "приђу",
  "приши",
  "пришт",
  "пркос",
  "проба",
  "прода",
  "проји",
  "проли",
  "проса",
  "прост",
  "прота",
  "проти",
  "протр",
  "проту",
  "прова",
  "проза",
  "проћи",
  "прође",
  "прођи",
  "прођу",
  "проши",
  "проже",
  "прска",
  "прсна",
  "прсни",
  "прсну",
  "прсти",
  "пруго",
  "пруса",
  "прузи",
  "прућа",
  "пружа",
  "пружи",
  "првак",
  "првих",
  "првим",
  "првог",
  "првој",
  "првом",
  "прчка",
  "прште",
  "пршти",
  "пршут",
  "пржен",
  "пржун",
  "псари",
  "псета",
  "псето",
  "псету",
  "псећа",
  "псећи",
  "псећу",
  "психа",
  "псима",
  "псина",
  "псића",
  "псича",
  "псичи",
  "псичу",
  "псују",
  "птица",
  "птипа",
  "птића",
  "птићи",
  "птићу",
  "птиче",
  "пуцач",
  "пуцом",
  "пудер",
  "пудла",
  "пудли",
  "пудлу",
  "пухат",
  "пухне",
  "пухни",
  "пухну",
  "пукла",
  "пукли",
  "пукне",
  "пукни",
  "пукну",
  "пуког",
  "пулпа",
  "пулпи",
  "пулпу",
  "пумпа",
  "пунац",
  "пунцу",
  "пункт",
  "пуног",
  "пунча",
  "пунђа",
  "пупак",
  "пупци",
  "пупка",
  "пупку",
  "пуран",
  "пуром",
  "пусом",
  "путар",
  "путем",
  "путен",
  "путић",
  "путна",
  "путра",
  "путуј",
  "пучем",
  "пучка",
  "пушач",
  "пушци",
  "пушем",
  "пушеш",
  "пуших",
  "пушка",
  "пушта",
  "пужем",
  "пужеш",
  "пужна",
  "пчела",
  "рабат",
  "рабин",
  "рабит",
  "рацом",
  "радан",
  "радар",
  "радиј",
  "радин",
  "радит",
  "радић",
  "радна",
  "рафал",
  "рагби",
  "рагом",
  "рахла",
  "рахли",
  "рахлу",
  "рајем",
  "рајон",
  "ракун",
  "рампа",
  "рампи",
  "рампу",
  "ранац",
  "ранар",
  "ранца",
  "ранга",
  "рангу",
  "раник",
  "ранка",
  "ранки",
  "ранку",
  "раног",
  "ранче",
  "рарог",
  "расад",
  "расап",
  "расла",
  "расна",
  "расни",
  "расну",
  "расол",
  "расом",
  "распе",
  "распи",
  "распу",
  "расти",
  "расут",
  "ратар",
  "ратна",
  "раван",
  "равна",
  "разби",
  "разла",
  "разли",
  "разна",
  "разор",
  "разум",
  "разви",
  "рачић",
  "рачја",
  "рачји",
  "рачју",
  "рачун",
  "рачва",
  "рашиј",
  "рашка",
  "рашки",
  "рашку",
  "рашће",
  "ражју",
  "ребра",
  "ребро",
  "ребру",
  "ребус",
  "рецој",
  "редак",
  "редар",
  "редци",
  "редем",
  "редна",
  "редни",
  "редну",
  "редов",
  "рефул",
  "реиса",
  "рекао",
  "рекет",
  "рекла",
  "рекне",
  "рекни",
  "рекну",
  "рекох",
  "релеј",
  "ремек",
  "ремен",
  "ремих",
  "ремом",
  "реној",
  "рента",
  "репер",
  "репић",
  "рерна",
  "ресих",
  "реска",
  "ресом",
  "ресор",
  "ретка",
  "ретку",
  "реума",
  "реван",
  "ревер",
  "ревир",
  "ревна",
  "резак",
  "резач",
  "резон",
  "речем",
  "речен",
  "ређаш",
  "режем",
  "режеш",
  "режим",
  "режиш",
  "рибар",
  "рибеж",
  "рибиз",
  "рибић",
  "рибич",
  "рибом",
  "ријеч",
  "рикне",
  "рикни",
  "рикну",
  "риком",
  "рикша",
  "рикши",
  "рикшу",
  "рилом",
  "римом",
  "ринем",
  "ринга",
  "рингу",
  "ринух",
  "рисач",
  "ритер",
  "ритма",
  "ритму",
  "ритне",
  "ривал",
  "ривен",
  "ривим",
  "ривом",
  "ризик",
  "ризја",
  "ризји",
  "ризју",
  "ризом",
  "ричем",
  "ричеш",
  "риђан",
  "ришем",
  "ришеш",
  "рижом",
  "рјеђа",
  "рјеђи",
  "рјеђу",
  "робна",
  "робни",
  "робну",
  "робот",
  "родан",
  "родит",
  "родна",
  "рогат",
  "рогач",
  "рогоз",
  "рохав",
  "ројем",
  "ројта",
  "ројти",
  "ројту",
  "рокће",
  "рокћи",
  "рокћу",
  "ролни",
  "ролои",
  "ролом",
  "роман",
  "ромон",
  "ронац",
  "росна",
  "росни",
  "росну",
  "росом",
  "ротор",
  "роваш",
  "ровца",
  "ровци",
  "ровцу",
  "ровка",
  "ровко",
  "розга",
  "розги",
  "розгу",
  "рочна",
  "рођак",
  "рођен",
  "рошах",
  "ртова",
  "ртови",
  "рубац",
  "рубим",
  "рубин",
  "рубна",
  "рудар",
  "рудна",
  "рудом",
  "ругло",
  "руглу",
  "рухом",
  "руина",
  "рујан",
  "рујем",
  "рујеш",
  "рујна",
  "рујни",
  "рујну",
  "рукав",
  "рукне",
  "руком",
  "румен",
  "румом",
  "рунда",
  "рунди",
  "рунду",
  "руном",
  "рупца",
  "рупци",
  "рупцу",
  "рупом",
  "рупче",
  "русих",
  "русим",
  "руска",
  "русој",
  "русом",
  "рутав",
  "рутом",
  "ручак",
  "ручка",
  "ручна",
  "рушит",
  "ружан",
  "ружем",
  "ружит",
  "ружна",
  "рвају",
  "рвало",
  "рвати",
  "рвача",
  "рвачи",
  "рвачу",
  "рзамо",
  "рзати",
  "рђају",
  "рђала",
  "рђама",
  "рђати",
  "ржући",
  "сабат",
  "сабиј",
  "сабор",
  "сабра",
  "садит",
  "садре",
  "садри",
  "садро",
  "садрт",
  "садру",
  "сафир",
  "сагне",
  "сагни",
  "сагну",
  "сахне",
  "сахни",
  "сахну",
  "сајам",
  "сајла",
  "сајли",
  "сајлу",
  "сајма",
  "сајмо",
  "сајму",
  "сакат",
  "сакоа",
  "сакои",
  "сакоу",
  "сакри",
  "сакса",
  "саксу",
  "салаш",
  "салда",
  "салдо",
  "салду",
  "салих",
  "салом",
  "салон",
  "салпа",
  "салпи",
  "салпу",
  "салва",
  "самац",
  "самар",
  "самца",
  "самци",
  "самцу",
  "самит",
  "самоа",
  "самог",
  "самта",
  "самче",
  "санак",
  "санци",
  "санен",
  "санка",
  "санку",
  "санта",
  "сапет",
  "сапне",
  "сапни",
  "сапну",
  "сапом",
  "сапун",
  "сарма",
  "саром",
  "саску",
  "саспе",
  "саспи",
  "саспу",
  "сасух",
  "сатен",
  "сатир",
  "сатић",
  "сатна",
  "сатни",
  "сатну",
  "сатре",
  "сатру",
  "сауна",
  "сауни",
  "сауну",
  "савез",
  "сазив",
  "сазре",
  "сазри",
  "сазру",
  "сазуј",
  "сазва",
  "саћем",
  "сачем",
  "сачма",
  "сачми",
  "сачму",
  "сађен",
  "саших",
  "сашио",
  "сашом",
  "сажех",
  "сажет",
  "сажме",
  "сажми",
  "сажму",
  "сцена",
  "себра",
  "себри",
  "себру",
  "седам",
  "седеф",
  "седла",
  "седло",
  "седлу",
  "седма",
  "сејом",
  "секса",
  "секси",
  "сексу",
  "секта",
  "селца",
  "селци",
  "селцу",
  "селен",
  "селит",
  "селом",
  "сенат",
  "сеоба",
  "сеоца",
  "сепса",
  "серач",
  "сереш",
  "серум",
  "сезам",
  "сезаш",
  "сежем",
  "сфера",
  "схема",
  "схрва",
  "сибир",
  "сидом",
  "сидра",
  "сифон",
  "сигом",
  "сијат",
  "сијаш",
  "сијед",
  "сијем",
  "сијеш",
  "сијим",
  "сикћа",
  "сикћи",
  "сикћу",
  "силан",
  "силаз",
  "силит",
  "силна",
  "силом",
  "силос",
  "синци",
  "синем",
  "синеш",
  "сингл",
  "синко",
  "синку",
  "синоћ",
  "синух",
  "синус",
  "синут",
  "синче",
  "сипин",
  "сипка",
  "сипки",
  "сипку",
  "сипој",
  "сипом",
  "сирац",
  "сирак",
  "сирца",
  "сирци",
  "сирцу",
  "сиров",
  "сируп",
  "сисак",
  "сисам",
  "сисар",
  "сиска",
  "сисом",
  "ситан",
  "ситар",
  "ситна",
  "ситог",
  "сивца",
  "сивци",
  "сивцу",
  "сивог",
  "сићих",
  "сићој",
  "сићом",
  "сиђем",
  "сиђеш",
  "сиђих",
  "сиђох",
  "сишао",
  "сишем",
  "сишеш",
  "сишла",
  "сишли",
  "сишој",
  "сјаха",
  "сјаја",
  "сјаји",
  "сјају",
  "сјала",
  "сјало",
  "сјати",
  "сјашу",
  "сједа",
  "сједи",
  "сједу",
  "сјела",
  "сјеме",
  "сјена",
  "сјени",
  "сјену",
  "сјест",
  "сјета",
  "сјети",
  "сјећа",
  "сјећи",
  "сјеча",
  "сјечи",
  "сјечу",
  "скала",
  "скалп",
  "скаут",
  "скача",
  "скачи",
  "скачу",
  "скела",
  "скица",
  "скида",
  "скија",
  "скији",
  "скију",
  "скине",
  "скини",
  "скину",
  "скита",
  "скиће",
  "скиче",
  "склад",
  "склон",
  "склоп",
  "скоба",
  "скока",
  "скоку",
  "скора",
  "скова",
  "скочи",
  "скрби",
  "скрбу",
  "скрих",
  "скроз",
  "скрши",
  "скршу",
  "скуби",
  "скуца",
  "скуха",
  "скује",
  "скута",
  "скути",
  "скуту",
  "скучи",
  "скуша",
  "слаци",
  "слајд",
  "слала",
  "слама",
  "слапи",
  "сласт",
  "слати",
  "слава",
  "слађа",
  "слађи",
  "слађу",
  "слаже",
  "слажи",
  "слажу",
  "следи",
  "следу",
  "сленг",
  "слети",
  "слећи",
  "слеђа",
  "слеђу",
  "слеже",
  "слежу",
  "слици",
  "слије",
  "слију",
  "слика",
  "слико",
  "слику",
  "слила",
  "слили",
  "слило",
  "слина",
  "слити",
  "сличи",
  "слого",
  "слоја",
  "слоји",
  "слоју",
  "слони",
  "слова",
  "слози",
  "сложи",
  "слуга",
  "слуха",
  "слухи",
  "слуху",
  "слуси",
  "слути",
  "слузи",
  "случи",
  "слуша",
  "служи",
  "смаћи",
  "смаче",
  "смаже",
  "смажи",
  "смажу",
  "смела",
  "смели",
  "смета",
  "смети",
  "смету",
  "смећа",
  "смећу",
  "смеча",
  "смије",
  "смију",
  "смили",
  "смион",
  "смири",
  "смичу",
  "смјех",
  "смјер",
  "смоци",
  "смога",
  "смогу",
  "смоје",
  "смоју",
  "смока",
  "смоку",
  "смола",
  "смоћи",
  "смочи",
  "сможе",
  "смрад",
  "смрде",
  "смрди",
  "смрси",
  "смрти",
  "смрви",
  "смрћу",
  "смрча",
  "смрчи",
  "смрчу",
  "смути",
  "смуша",
  "снага",
  "снаго",
  "снагу",
  "снаха",
  "снаси",
  "снази",
  "снаћи",
  "снађе",
  "снађи",
  "снађу",
  "снажи",
  "снесе",
  "снеси",
  "снесу",
  "снима",
  "сними",
  "снизи",
  "сноси",
  "снова",
  "снови",
  "снуби",
  "снује",
  "снују",
  "собар",
  "собна",
  "содом",
  "софом",
  "сојем",
  "сојки",
  "сокак",
  "сокна",
  "сокни",
  "сокну",
  "сокол",
  "сомун",
  "сонда",
  "сонди",
  "сонду",
  "сонет",
  "сонга",
  "сопће",
  "сопћи",
  "сопћу",
  "сорта",
  "сорти",
  "сорту",
  "совом",
  "сочан",
  "сочна",
  "спаја",
  "спала",
  "спали",
  "спалу",
  "спати",
  "спава",
  "спази",
  "специ",
  "списи",
  "спите",
  "сплав",
  "сплет",
  "спона",
  "спорт",
  "спота",
  "споту",
  "спрам",
  "спрат",
  "спрда",
  "спруд",
  "спрва",
  "спржи",
  "спуст",
  "спута",
  "срами",
  "срати",
  "сразо",
  "србим",
  "србин",
  "србом",
  "србуј",
  "срцем",
  "срдит",
  "среди",
  "срела",
  "срели",
  "срест",
  "срете",
  "среза",
  "срећа",
  "среже",
  "срежу",
  "сриче",
  "сричи",
  "сричу",
  "сркат",
  "сркне",
  "сркни",
  "сркну",
  "срмом",
  "срнем",
  "срном",
  "срнут",
  "срока",
  "сроку",
  "сроза",
  "српем",
  "српом",
  "сручи",
  "сруши",
  "срчан",
  "срчем",
  "срчеш",
  "сржју",
  "стада",
  "стадо",
  "стаду",
  "стаја",
  "стала",
  "стали",
  "стало",
  "стана",
  "стапа",
  "старт",
  "стати",
  "стаза",
  "стажа",
  "стажи",
  "стажу",
  "стеци",
  "стега",
  "стего",
  "стегу",
  "стеку",
  "степа",
  "стера",
  "стеве",
  "стези",
  "стећи",
  "стече",
  "стеже",
  "стежи",
  "стежу",
  "стиди",
  "стиха",
  "стиху",
  "стине",
  "стини",
  "стину",
  "стипи",
  "стићи",
  "стиче",
  "стиша",
  "стиже",
  "стижи",
  "стижу",
  "стоци",
  "стога",
  "стогу",
  "стоик",
  "стоје",
  "стоји",
  "стока",
  "стоко",
  "стоку",
  "стота",
  "стоти",
  "стоту",
  "сточе",
  "стоже",
  "страх",
  "стран",
  "стрци",
  "стрем",
  "стрес",
  "стрга",
  "стриц",
  "стрип",
  "стрка",
  "стрко",
  "стрку",
  "строг",
  "строј",
  "строп",
  "стрпа",
  "стрпи",
  "стрте",
  "стрти",
  "струг",
  "струк",
  "стрви",
  "стрчи",
  "стрши",
  "стрже",
  "стуба",
  "стући",
  "стуче",
  "ствар",
  "створ",
  "суцем",
  "судац",
  "судар",
  "судба",
  "судби",
  "судбу",
  "судца",
  "судит",
  "сухог",
  "суита",
  "суити",
  "суиту",
  "сукно",
  "сукоб",
  "сукус",
  "сумом",
  "сунца",
  "сунцу",
  "сунет",
  "сунча",
  "суочи",
  "супер",
  "супке",
  "сурци",
  "сурих",
  "сурим",
  "сурка",
  "сурки",
  "сурку",
  "сурла",
  "сурој",
  "суром",
  "суров",
  "сутла",
  "сутли",
  "сутлу",
  "сутон",
  "сутра",
  "суваг",
  "сузан",
  "сузна",
  "сузни",
  "сузну",
  "сузом",
  "сућут",
  "суђем",
  "сушац",
  "сушца",
  "сушци",
  "сушцу",
  "сушем",
  "сушен",
  "суших",
  "сушно",
  "сушој",
  "сушом",
  "сужуј",
  "свака",
  "свали",
  "сване",
  "свани",
  "свану",
  "свари",
  "сваст",
  "свати",
  "свађа",
  "свеца",
  "свеци",
  "свецу",
  "сведе",
  "сведи",
  "сведу",
  "свега",
  "свела",
  "свело",
  "свему",
  "свест",
  "свети",
  "свеза",
  "свече",
  "свеже",
  "свежи",
  "свежу",
  "свици",
  "свиде",
  "свиди",
  "свија",
  "свију",
  "свила",
  "свима",
  "свину",
  "свира",
  "свита",
  "свити",
  "свиће",
  "свићи",
  "свићу",
  "свиче",
  "свиђа",
  "свјеж",
  "свода",
  "свога",
  "своме",
  "свому",
  "свота",
  "свраб",
  "сврбе",
  "сврби",
  "сврха",
  "сврхо",
  "сврху",
  "сврне",
  "сврни",
  "сврну",
  "сврси",
  "сврћи",
  "сврши",
  "сврже",
  "свуци",
  "свуда",
  "свуку",
  "свући",
  "свуче",
  "шахом",
  "шахта",
  "шајке",
  "шакал",
  "шаком",
  "шаман",
  "шамар",
  "шанац",
  "шанца",
  "шанци",
  "шанцу",
  "шанса",
  "шанси",
  "шансу",
  "шапат",
  "шапне",
  "шапни",
  "шапну",
  "шапом",
  "шапта",
  "шапти",
  "шапту",
  "шапће",
  "шапћи",
  "шапћу",
  "шарац",
  "шараф",
  "шаран",
  "шарци",
  "шарен",
  "шарка",
  "шарко",
  "шарку",
  "шаром",
  "шаров",
  "шарун",
  "шаржа",
  "шаржи",
  "шаржу",
  "шатор",
  "шашав",
  "шашем",
  "шашко",
  "шегрт",
  "шеике",
  "шеноа",
  "шенои",
  "шеноу",
  "шенух",
  "шенут",
  "шепав",
  "шериф",
  "шерпа",
  "шетач",
  "шеташ",
  "шевар",
  "шевом",
  "шећем",
  "шећер",
  "шећеш",
  "шешир",
  "шибер",
  "шибом",
  "шифра",
  "шијем",
  "шијеш",
  "шијом",
  "шикне",
  "шикни",
  "шикну",
  "шилок",
  "шилом",
  "шимом",
  "шимун",
  "шином",
  "шипак",
  "шипци",
  "шипка",
  "шипки",
  "шипку",
  "шипче",
  "ширег",
  "ширем",
  "ширих",
  "ширит",
  "широј",
  "широк",
  "широм",
  "шишка",
  "шишки",
  "шишку",
  "шкамп",
  "шкара",
  "шкарт",
  "шкару",
  "шкија",
  "шкода",
  "шкоди",
  "шкоду",
  "школа",
  "шкопи",
  "шкоти",
  "шкрца",
  "шкрци",
  "шкрцу",
  "шкрга",
  "шкроб",
  "шкрче",
  "шкуда",
  "шкуди",
  "шкуду",
  "шкура",
  "шлапа",
  "шлапи",
  "шлапу",
  "шлица",
  "шофер",
  "шогор",
  "шојки",
  "шојку",
  "шокац",
  "шокца",
  "шокци",
  "шокцу",
  "шоком",
  "шолта",
  "шолти",
  "шолту",
  "шпади",
  "шпага",
  "шпана",
  "шпану",
  "шпени",
  "шпица",
  "шпици",
  "шпицу",
  "шпигл",
  "шпорт",
  "штаци",
  "штака",
  "штако",
  "штаку",
  "штала",
  "штанд",
  "штави",
  "штеди",
  "штеки",
  "штене",
  "штета",
  "штије",
  "штију",
  "штима",
  "штипа",
  "штита",
  "штива",
  "штофа",
  "штоса",
  "штова",
  "штрца",
  "штуца",
  "штује",
  "штују",
  "штука",
  "штула",
  "штура",
  "штури",
  "штуру",
  "шубић",
  "шугав",
  "шугом",
  "шуица",
  "шукер",
  "шулек",
  "шуман",
  "шумар",
  "шумна",
  "шумни",
  "шумну",
  "шумор",
  "шунду",
  "шунка",
  "шупак",
  "шупом",
  "шутим",
  "шутио",
  "шутиш",
  "шутке",
  "шушка",
  "шушки",
  "шушку",
  "шушом",
  "шуште",
  "шваба",
  "швели",
  "швело",
  "швелу",
  "шчепа",
  "табак",
  "табан",
  "табла",
  "табор",
  "табуа",
  "табуи",
  "табуу",
  "тацом",
  "тафта",
  "тајац",
  "тајан",
  "тајге",
  "тајна",
  "тајом",
  "тајче",
  "такао",
  "такав",
  "такли",
  "такне",
  "такни",
  "такну",
  "таком",
  "такса",
  "таква",
  "талац",
  "талир",
  "талог",
  "талон",
  "талче",
  "таман",
  "тамна",
  "тамом",
  "танац",
  "танад",
  "танак",
  "танан",
  "танца",
  "танга",
  "танго",
  "тангу",
  "танин",
  "танче",
  "таоца",
  "таоци",
  "таоцу",
  "тапка",
  "тапша",
  "тарац",
  "тарем",
  "тареш",
  "таром",
  "татар",
  "таван",
  "тавом",
  "тачке",
  "тачку",
  "тажен",
  "тегла",
  "тегли",
  "теглу",
  "тегом",
  "текао",
  "текла",
  "текли",
  "текст",
  "телац",
  "телад",
  "телал",
  "телић",
  "темом",
  "темпа",
  "темпо",
  "темпу",
  "тенис",
  "тенор",
  "тепих",
  "тепли",
  "терца",
  "терци",
  "терцу",
  "терен",
  "терет",
  "терми",
  "термо",
  "терор",
  "тесар",
  "тесла",
  "тесли",
  "теслу",
  "тетак",
  "тетка",
  "тетом",
  "теута",
  "тезга",
  "тезги",
  "тезгу",
  "тезом",
  "течај",
  "течан",
  "течем",
  "течеш",
  "течна",
  "тешка",
  "тежак",
  "тежег",
  "тежем",
  "тежих",
  "тежој",
  "тежом",
  "тицам",
  "тифус",
  "тигар",
  "тигра",
  "тигру",
  "тихог",
  "тијек",
  "тиком",
  "тиква",
  "тилдо",
  "тимар",
  "тимор",
  "тинел",
  "тинта",
  "типка",
  "тирол",
  "тисак",
  "тиска",
  "тиску",
  "тисна",
  "тисни",
  "тисну",
  "тисом",
  "титра",
  "тичем",
  "тичеш",
  "тишем",
  "тиших",
  "тишим",
  "тишма",
  "тишој",
  "тишом",
  "тиште",
  "тишти",
  "тјеме",
  "тјера",
  "тјеши",
  "ткају",
  "ткати",
  "ткачи",
  "ткива",
  "ткиво",
  "ткиву",
  "тлаци",
  "тлапи",
  "тлачи",
  "тлима",
  "тмица",
  "тмици",
  "тмицу",
  "тмина",
  "тобом",
  "токар",
  "толик",
  "томац",
  "томца",
  "тонем",
  "тонеш",
  "топао",
  "топаз",
  "топит",
  "топић",
  "топла",
  "топот",
  "топта",
  "топуз",
  "торба",
  "торта",
  "торзо",
  "тотем",
  "товар",
  "товит",
  "товна",
  "товни",
  "товну",
  "точак",
  "точан",
  "точци",
  "точка",
  "точна",
  "траци",
  "трага",
  "трагу",
  "траја",
  "трају",
  "трако",
  "тракт",
  "трами",
  "транс",
  "трапи",
  "траса",
  "трати",
  "трава",
  "трача",
  "тражи",
  "трбух",
  "треба",
  "трема",
  "тренд",
  "тресе",
  "треси",
  "трест",
  "тресу",
  "трете",
  "трећа",
  "трећи",
  "трећу",
  "тргне",
  "тргни",
  "тргну",
  "тргох",
  "тргом",
  "трица",
  "трија",
  "трију",
  "трика",
  "трико",
  "трику",
  "трима",
  "триом",
  "трипа",
  "триче",
  "тркач",
  "тркин",
  "тркне",
  "трнци",
  "трнем",
  "трнеш",
  "трнов",
  "трнче",
  "трога",
  "троја",
  "троји",
  "трона",
  "трону",
  "тропа",
  "троши",
  "трпак",
  "трпим",
  "трпио",
  "трпиш",
  "трпка",
  "трпна",
  "трпни",
  "трпну",
  "трпом",
  "трсат",
  "трсит",
  "трсја",
  "трсју",
  "трска",
  "трста",
  "тртих",
  "тртим",
  "тртој",
  "тртом",
  "труба",
  "труди",
  "трује",
  "трују",
  "трула",
  "труни",
  "трупи",
  "труси",
  "труст",
  "трвен",
  "трзај",
  "трчах",
  "трчци",
  "трчим",
  "трчиш",
  "трчка",
  "трчки",
  "трчку",
  "тржна",
  "тубом",
  "туцет",
  "тугом",
  "тукац",
  "тукао",
  "тукла",
  "тукли",
  "тукло",
  "туком",
  "тулум",
  "тумач",
  "тумор",
  "тунел",
  "туном",
  "тупог",
  "турбе",
  "турке",
  "туста",
  "тусти",
  "тусту",
  "тутор",
  "тузле",
  "тузли",
  "тузлу",
  "тучак",
  "тучем",
  "тучен",
  "тучеш",
  "тучке",
  "тучом",
  "туђег",
  "туђем",
  "туђин",
  "тушем",
  "тужан",
  "тужба",
  "тужен",
  "тужит",
  "тужна",
  "твари",
  "твога",
  "твоме",
  "твому",
  "твори",
  "тврци",
  "тврђа",
  "тврђи",
  "тврђу",
  "убаци",
  "убере",
  "убери",
  "уберу",
  "убици",
  "убију",
  "убила",
  "убили",
  "убило",
  "убире",
  "убири",
  "убиру",
  "убита",
  "убити",
  "убише",
  "убоди",
  "убоје",
  "убола",
  "уболи",
  "уболо",
  "убост",
  "убрах",
  "убрус",
  "убрза",
  "убрзо",
  "удаде",
  "удаху",
  "удаја",
  "удала",
  "удана",
  "удану",
  "удаси",
  "удата",
  "удати",
  "удату",
  "удаше",
  "удица",
  "удиса",
  "удиви",
  "удише",
  "удиши",
  "удишу",
  "удоми",
  "удова",
  "удови",
  "удову",
  "удреш",
  "уфају",
  "уфало",
  "уфати",
  "угане",
  "угани",
  "угану",
  "угари",
  "угаси",
  "угази",
  "угађа",
  "угиба",
  "углас",
  "углат",
  "углед",
  "углом",
  "угнај",
  "угнем",
  "угода",
  "угоди",
  "угоду",
  "угоји",
  "угора",
  "угриз",
  "угуши",
  "ухити",
  "ухода",
  "ујаци",
  "ујаче",
  "ујаше",
  "уједи",
  "ујела",
  "ујели",
  "ујело",
  "ујина",
  "ујини",
  "ујину",
  "ујном",
  "укази",
  "укаже",
  "укажи",
  "укажу",
  "укида",
  "укине",
  "укини",
  "укину",
  "укопи",
  "укосо",
  "укочи",
  "украс",
  "укрца",
  "укруг",
  "укуха",
  "укуси",
  "улази",
  "улаже",
  "улажи",
  "улажу",
  "улица",
  "улије",
  "улију",
  "улика",
  "улила",
  "улили",
  "улило",
  "улита",
  "улити",
  "улкус",
  "улози",
  "уложи",
  "улудо",
  "умаци",
  "умага",
  "умагу",
  "умало",
  "умара",
  "умаћи",
  "умаче",
  "умеци",
  "умете",
  "умеће",
  "умећи",
  "умећу",
  "умече",
  "умије",
  "умију",
  "умили",
  "умине",
  "умини",
  "умину",
  "умири",
  "умиру",
  "умита",
  "умити",
  "умише",
  "умјет",
  "умних",
  "умник",
  "умним",
  "умној",
  "умном",
  "умоли",
  "умота",
  "умова",
  "умови",
  "умочи",
  "умреш",
  "умрла",
  "умрли",
  "умрлу",
  "унцом",
  "унесе",
  "унеси",
  "унесу",
  "унфпа",
  "унхцр",
  "унија",
  "унићи",
  "униђе",
  "униђи",
  "униђу",
  "уноси",
  "унска",
  "унски",
  "унску",
  "унуци",
  "унуко",
  "унуче",
  "уочит",
  "упади",
  "упала",
  "упери",
  "упећи",
  "упија",
  "упију",
  "упила",
  "уписи",
  "упита",
  "упиша",
  "упиши",
  "упишу",
  "уплео",
  "уплив",
  "упола",
  "упора",
  "упори",
  "упору",
  "управ",
  "упрем",
  "упрли",
  "упрта",
  "упрти",
  "упрту",
  "упута",
  "ураци",
  "уради",
  "урама",
  "урари",
  "уреја",
  "уреји",
  "уреју",
  "уреом",
  "урези",
  "урећи",
  "урече",
  "уреже",
  "урежи",
  "урежу",
  "урица",
  "урици",
  "урицу",
  "урини",
  "урлик",
  "урних",
  "урним",
  "урној",
  "урном",
  "уроци",
  "урони",
  "урота",
  "уручи",
  "уруши",
  "усади",
  "усана",
  "усели",
  "усере",
  "усери",
  "усеру",
  "усхит",
  "усили",
  "усима",
  "усири",
  "усиса",
  "усише",
  "усиши",
  "усишу",
  "усјек",
  "усјев",
  "уских",
  "уским",
  "уског",
  "уској",
  "уском",
  "усмен",
  "уснем",
  "уснен",
  "уснеш",
  "усних",
  "усним",
  "усној",
  "усном",
  "уснух",
  "уснут",
  "усоли",
  "усови",
  "успје",
  "успне",
  "успни",
  "успну",
  "успон",
  "успут",
  "усрах",
  "усред",
  "устав",
  "устук",
  "усула",
  "усути",
  "утаја",
  "утаји",
  "утају",
  "утаче",
  "утажи",
  "утеку",
  "утези",
  "утећи",
  "утече",
  "утиха",
  "утире",
  "утиче",
  "утичи",
  "утичу",
  "утиша",
  "уткан",
  "утоку",
  "утоли",
  "утоне",
  "утони",
  "утону",
  "утопи",
  "утори",
  "утови",
  "утрци",
  "утрем",
  "утрка",
  "утрко",
  "утрку",
  "утрли",
  "утрне",
  "утрни",
  "утрну",
  "утрпа",
  "утрча",
  "утрчи",
  "утуре",
  "утуве",
  "утуви",
  "утући",
  "утуче",
  "утужи",
  "увала",
  "уважа",
  "уважи",
  "уведе",
  "уведи",
  "уведу",
  "увела",
  "увели",
  "увелу",
  "увене",
  "увени",
  "увену",
  "увест",
  "увези",
  "увећа",
  "увеже",
  "увежи",
  "увежу",
  "увиди",
  "увије",
  "увиле",
  "увита",
  "увити",
  "увито",
  "увиђа",
  "увјет",
  "уводи",
  "увози",
  "увуку",
  "увући",
  "увуче",
  "уздах",
  "уздан",
  "уздем",
  "уздом",
  "уздух",
  "уздуж",
  "узела",
  "узели",
  "узело",
  "узгој",
  "узгон",
  "узица",
  "узима",
  "узићи",
  "узиђе",
  "узиђи",
  "узиђу",
  "узлаз",
  "узлет",
  "узлом",
  "узмак",
  "узмем",
  "узмеш",
  "узник",
  "узнио",
  "узори",
  "узрок",
  "узуса",
  "узвик",
  "учаху",
  "учаше",
  "учећи",
  "учила",
  "учини",
  "учити",
  "учтив",
  "уђемо",
  "уђете",
  "уђимо",
  "уђите",
  "уђоше",
  "ушара",
  "ушари",
  "ушару",
  "ушица",
  "ушици",
  "ушицу",
  "ушије",
  "ушију",
  "ушима",
  "ушита",
  "ушити",
  "ушком",
  "ушних",
  "ушним",
  "ушној",
  "ушном",
  "уштап",
  "ушуте",
  "ушути",
  "ушћем",
  "ужади",
  "ужаси",
  "ужета",
  "ужету",
  "ужећи",
  "ужеже",
  "ужгах",
  "ужици",
  "ужима",
  "ужина",
  "ужива",
  "уживи",
  "ужиже",
  "вабац",
  "вабом",
  "вадит",
  "вагат",
  "вагаш",
  "вагне",
  "вагни",
  "вагну",
  "вагом",
  "вагон",
  "вајда",
  "вајди",
  "вајду",
  "вајна",
  "вајни",
  "вајну",
  "вакуф",
  "вална",
  "вапај",
  "вапит",
  "вапна",
  "вапно",
  "вапну",
  "вапче",
  "варав",
  "варци",
  "варка",
  "варко",
  "варку",
  "варош",
  "варче",
  "ватра",
  "вазал",
  "вазда",
  "вазом",
  "вађен",
  "вашар",
  "вашег",
  "вашем",
  "важан",
  "важна",
  "ведар",
  "ведра",
  "велик",
  "велим",
  "велиш",
  "велне",
  "велом",
  "венем",
  "венеш",
  "веном",
  "веома",
  "вепар",
  "вепра",
  "вепру",
  "верем",
  "вереш",
  "весео",
  "весла",
  "весло",
  "веслу",
  "весна",
  "весну",
  "вести",
  "ветом",
  "везан",
  "везат",
  "везач",
  "везем",
  "везен",
  "везеш",
  "везир",
  "везла",
  "везна",
  "везни",
  "везну",
  "већег",
  "већем",
  "вечер",
  "вежем",
  "вежом",
  "вицем",
  "вицом",
  "видан",
  "видеа",
  "видео",
  "видеу",
  "видик",
  "видим",
  "видио",
  "видиш",
  "видна",
  "видра",
  "вихор",
  "вијак",
  "вијци",
  "вијек",
  "вијем",
  "вијеш",
  "вијка",
  "вијку",
  "вијче",
  "виках",
  "викар",
  "викач",
  "викне",
  "викни",
  "викну",
  "виком",
  "вилом",
  "винар",
  "винца",
  "винцу",
  "винем",
  "винеш",
  "винил",
  "вином",
  "виола",
  "виоли",
  "виолу",
  "вирус",
  "висак",
  "висим",
  "висио",
  "висиш",
  "виска",
  "виски",
  "виску",
  "висла",
  "висли",
  "висок",
  "витак",
  "витао",
  "витез",
  "витка",
  "витла",
  "визир",
  "визом",
  "вичан",
  "вичем",
  "вичеш",
  "вична",
  "виђен",
  "вишак",
  "вишег",
  "вишем",
  "виших",
  "вишим",
  "вишка",
  "вишки",
  "вишку",
  "вишој",
  "вишом",
  "вижла",
  "вижли",
  "вижлу",
  "вјера",
  "вјеђа",
  "вјеша",
  "вјешт",
  "влада",
  "влага",
  "влаго",
  "влагу",
  "влаха",
  "влаху",
  "влаји",
  "влака",
  "влаку",
  "власи",
  "власт",
  "влати",
  "влази",
  "влаћу",
  "влаче",
  "влажи",
  "воден",
  "водик",
  "водит",
  "водич",
  "водна",
  "војак",
  "војко",
  "војна",
  "вокал",
  "волан",
  "волга",
  "волги",
  "волгу",
  "волим",
  "волио",
  "волиш",
  "волти",
  "восак",
  "воска",
  "воску",
  "возар",
  "возач",
  "возит",
  "возна",
  "воћар",
  "воћем",
  "воћка",
  "воћна",
  "воћни",
  "воћну",
  "воћом",
  "вођен",
  "вођом",
  "вошти",
  "врана",
  "врази",
  "враћа",
  "врача",
  "враже",
  "врбем",
  "врбик",
  "врбом",
  "врбов",
  "врцну",
  "врдај",
  "вреба",
  "врела",
  "врети",
  "врева",
  "врећа",
  "вргао",
  "вргли",
  "вргне",
  "вргни",
  "вргну",
  "вргох",
  "врхом",
  "врије",
  "врију",
  "вримо",
  "врите",
  "врити",
  "врлет",
  "врлих",
  "врлим",
  "врлог",
  "врлој",
  "врлом",
  "врпца",
  "врсна",
  "врста",
  "вртим",
  "вртио",
  "вртић",
  "вртиш",
  "вртна",
  "вртни",
  "вртну",
  "вруци",
  "вруче",
  "врвим",
  "врвио",
  "врвиш",
  "врчем",
  "вршак",
  "вршци",
  "вршка",
  "вршна",
  "вршом",
  "вршче",
  "вукао",
  "вукла",
  "вукли",
  "вукло",
  "вулве",
  "вунен",
  "вуном",
  "вучак",
  "вучем",
  "вучеш",
  "вучић",
  "вучја",
  "вучји",
  "вучју",
  "вучка",
  "вучна",
  "вучом",
  "забат",
  "забит",
  "задах",
  "задак",
  "задан",
  "задат",
  "задре",
  "задри",
  "задру",
  "заход",
  "зајам",
  "зајео",
  "зајма",
  "зајми",
  "зајму",
  "закла",
  "закон",
  "закуп",
  "залаз",
  "залет",
  "залих",
  "залим",
  "залог",
  "замах",
  "замак",
  "замци",
  "замео",
  "замет",
  "замка",
  "замор",
  "замре",
  "занат",
  "занио",
  "занос",
  "заова",
  "запад",
  "запах",
  "запис",
  "запне",
  "запни",
  "запну",
  "запор",
  "запре",
  "запри",
  "запру",
  "запух",
  "зарад",
  "зарез",
  "зариј",
  "зарза",
  "зарђа",
  "зарже",
  "засиј",
  "засја",
  "заспа",
  "заспи",
  "заспу",
  "засун",
  "затим",
  "затон",
  "затор",
  "затре",
  "затри",
  "затру",
  "заузе",
  "завих",
  "завод",
  "завој",
  "завор",
  "завре",
  "зазор",
  "зачас",
  "зачин",
  "зачне",
  "зађем",
  "зађеш",
  "зађох",
  "зашао",
  "зашло",
  "зашто",
  "збаци",
  "збија",
  "збију",
  "збило",
  "збира",
  "збиру",
  "збита",
  "збити",
  "збиту",
  "збива",
  "збрци",
  "збрка",
  "збрко",
  "збрку",
  "зброј",
  "збуни",
  "здани",
  "здере",
  "здими",
  "здипи",
  "здола",
  "здрав",
  "здруг",
  "зебем",
  "зебеш",
  "зебом",
  "зебра",
  "зецом",
  "зефир",
  "зеком",
  "зелен",
  "земна",
  "земни",
  "земну",
  "зенит",
  "зером",
  "зетем",
  "зеуса",
  "зезај",
  "зечја",
  "зечји",
  "зечју",
  "згади",
  "згази",
  "зглоб",
  "згода",
  "згриј",
  "згрне",
  "згрни",
  "згрну",
  "згрће",
  "згрћи",
  "згрћу",
  "згрчи",
  "згуре",
  "зидар",
  "зидић",
  "зидна",
  "зидни",
  "зидну",
  "зијев",
  "зимим",
  "зимом",
  "зинем",
  "зипци",
  "зипка",
  "зиђем",
  "зјапи",
  "злата",
  "злима",
  "злоба",
  "злоте",
  "злоти",
  "злоћа",
  "змаја",
  "змају",
  "змија",
  "знаци",
  "знаде",
  "знаду",
  "знају",
  "знала",
  "знало",
  "знана",
  "знани",
  "знану",
  "знати",
  "значи",
  "зноја",
  "зноји",
  "зноју",
  "зобам",
  "зобун",
  "зоном",
  "зоран",
  "зорим",
  "зорио",
  "зорна",
  "зовем",
  "зовеш",
  "зовне",
  "зовни",
  "зовну",
  "зраци",
  "зрако",
  "зрачи",
  "зрела",
  "зрети",
  "зрије",
  "зрнат",
  "зрнца",
  "зрнцу",
  "зрним",
  "зрном",
  "зубац",
  "зубар",
  "зубна",
  "зујем",
  "зујим",
  "зујиш",
  "зулум",
  "зупца",
  "зупци",
  "зупцу",
  "зупче",
  "звала",
  "звало",
  "звати",
  "зваше",
  "звече",
  "звона",
  "зврка",
  "зврку",
  "зврче",
  "звуци",
  "звуча",
  "звучи",
  "жабом",
  "жагор",
  "жакет",
  "жалац",
  "жалба",
  "жалца",
  "жалци",
  "жалцу",
  "жалит",
  "жалче",
  "жамор",
  "жаоци",
  "жаока",
  "жарач",
  "жарен",
  "жарка",
  "жарна",
  "жарни",
  "жарну",
  "жбица",
  "жбуци",
  "жбука",
  "жбуко",
  "жбуку",
  "ждера",
  "ждери",
  "ждеру",
  "ждрал",
  "жедан",
  "жедна",
  "жегом",
  "желим",
  "желио",
  "желиш",
  "женик",
  "женка",
  "женом",
  "жепче",
  "жести",
  "жетва",
  "жезла",
  "жезло",
  "жезлу",
  "жешћа",
  "жешћи",
  "жешћу",
  "жгали",
  "жицом",
  "жидак",
  "жидов",
  "жигне",
  "жигом",
  "жилав",
  "жилет",
  "жилом",
  "жиром",
  "житак",
  "житка",
  "житна",
  "житни",
  "житну",
  "житом",
  "живац",
  "живад",
  "живца",
  "живци",
  "живцу",
  "живеж",
  "живин",
  "живио",
  "живиш",
  "живне",
  "живог",
  "живот",
  "живче",
  "жичан",
  "жична",
  "жични",
  "жичну",
  "жишци",
  "жишка",
  "жижом",
  "жлица",
  "жмаре",
  "жмири",
  "жнора",
  "жнори",
  "жнору",
  "жохар",
  "жрецу",
  "жртва",
  "жубор",
  "жудан",
  "жуких",
  "жуким",
  "жукој",
  "жуком",
  "жуном",
  "жупан",
  "жупна",
  "жупни",
  "жупну",
  "жупом",
  "журан",
  "журба",
  "журна",
  "жутог",
  "жућих",
  "жућим",
  "жућој",
  "жућом",
  "жучна",
  "жвала",
  "жвали",
  "жваче",
  "жвачи",
  "жвачу",
  "циган",
];

export default validWords;
